import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-95bc57f8"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UISpinner = _resolveComponent("UISpinner")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["bg-white rounded px-5", _ctx.newMobileScreens ? 'mx-4 py-2 border' : 'py-5 shadow'])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["materials-card-ttile font-semibold", _ctx.newMobileScreens ? 'text-lg text-gray-700' : 'text-xl'])
    }, " Files ", 2),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.postMaterials, (material) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["post-material", _ctx.newMobileScreens ? 'my-2' : 'my-4']),
        key: material.id
      }, [
        _createElementVNode("button", {
          class: _normalizeClass(["flex justify-between items-center post-material-link border-none focus:border-none", 
          _ctx.newMobileScreens ? _ctx.getMobileTheme().primaryText : 'text-blue-500'
        ]),
          onClick: ($event: any) => (_ctx.downloadFileFromURL(material.id, material.url, material.title))
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("img", {
              src: _ctx.getIcon(material.type),
              class: "post-material-icon mr-2 h-6"
            }, null, 8, _hoisted_3),
            _createElementVNode("a", {
              href: _ctx.replaceBucketUrlWithCdnUrl(material.url),
              onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"])),
              class: "post-material-title custom-word-break !no-underline"
            }, _toDisplayString(material.title), 9, _hoisted_4)
          ]),
          (_ctx.downloadingQueue.has(material.id))
            ? (_openBlock(), _createBlock(_component_UISpinner, {
                key: 0,
                size: "small"
              }))
            : _createCommentVNode("", true)
        ], 10, _hoisted_1)
      ], 2))
    }), 128))
  ], 2))
}