<template>
  <MobileNewYorkLessonTheme
    v-if="newMobileScreens && !isBuilder"
    :product="product"
    :instructor="instructor"
    :activePost="activePost"
    :category="category"
    :videoOptions="videoOptions"
    :playlist="playlist"
    :disableActions="disableActions"
    :lessonButton="lessonButton"
    :isAdmin="isAdmin"
    :categoryTree="categoryTree"
    :fetchingPost="fetchingPost"
    :videoCompletePercentage="videoCompletePercentage"
    :postMarkedAsCompleted="postMarkedAsCompleted"
    :postCompletionButtonText="postCompletionButtonText"
    :disablePostCompletionButton="disablePostCompletionButton"
    :fetchingPostCompletionStatus="fetchingPostCompletionStatus"
    :completedPostIds="completedPostIds"
    @togglePostCompletion="togglePostCompletion"
    :derivePostDescription="derivePostDescription"
    :findSubstringInstances="findSubstringInstances"
    :expandPostDetails="expandPostDetails"
    :gotoPreviousPost="gotoPreviousPost"
    :goToNextPost="goToNextPost"
    @updateBackData="updateBackData"
    @storeVideoProgress="storeVideoProgress"
    @updateVideoPlay="updateVideoPlay"
    @getVideoTime="getVideoTime"
  />
  <div
    v-else
    id="post-details-container newyork-template"
    :class="[
      disableActions && disableActions.pointerEvents === 'none' ? 'px-2' : '',
      fetching ? 'flex justify-center items-center h-scree' : '',
    ]"
  >
    <UISpinner v-if="fetching" size="large" />
    <div v-else>
      <div class="flex justify-between items-center">
        <CategoryPostBreadcrumbs
          :class="(activePost.video || activePost.audio) && 'lg:px-8'"
          :product-id="productId"
          :showIcon="true"
          :product-title="product && product.title"
          :go-back-icon-color="goBackIcon"
        />
        <div
          class="text-gray-500 flex font-medium pl-4 pt-2 md:pt-0 lessonSecondaryStyle lesson-path"
          :class="(activePost.video || activePost.audio) && 'lg:pr-10'"
        >
          <div
            v-if="shortendparentCategoryTitle"
            class="border-r border-gray-500 pr-2 mr-2"
          >
            {{ shortendparentCategoryTitle }}
          </div>
          <div v-if="categoryTitle" class="border-r border-gray-500 pr-2 mr-2">
            {{ categoryTitle }}
          </div>
          <div v-if="postTitle">
            {{ postTitle }}
          </div>
        </div>
      </div>
      <div class="post-details mt-4">
        <div class="video-post-body-container">
          <video-player
            id="post-video"
            v-if="activePost.video && videoOptions"
            class="items-center mb-5 player-height lg:px-10"
            :loading="fetchingPost"
            :video="activePost && activePost.video"
            :assetUrl="activePost && activePost.assetUrls"
            :options="videoOptions"
            video-width="w-full"
            :trackTime="true"
            :videoCompletePercentage="videoCompletePercentage"
            :video-thumbnail="activePost.posterImage"
            @getVideoTime="getVideoTime"
            @onPause="storeVideoProgress"
            @onEnded="storeVideoProgress"
            @onPlay="updateVideoPlay"
          />
          <audio-player
            v-else-if="activePost.audio"
            class="player-height mb-4"
            :audio="activePost.audio"
            :thumbnail="activePost.posterImage"
            :post="activePost"
            :product="product"
            :max-width="false"
            @getAudioTime="getAudioTime"
            @onPause="storeAudioProgress"
            @onEnded="storeAudioProgress"
            @onPlay="updateAudioPlay"
          />
          <div
            v-else
            class="relative post-body bg-white p-5 shadow rounded lesson-body-background overflow-y-scroll"
            :class="[
              expandPostData ? 'expanded-post-body' : 'post-body-description',
              currentPostContentType === postContentType.quiz
                ? 'flex justify-center'
                : '',
            ]"
            @mouseenter="showExpandIcon = true"
            @mouseleave="showExpandIcon = false"
          >
            <div
              v-if="currentPostContentType === postContentType.quiz"
              class="my-auto"
            >
              <QuizStatusComponent
                :post="activePost"
                :categoryId="categoryId"
                @changeQuizStatus="changeQuizStatus"
              />
            </div>
            <div
              v-else-if="currentPostContentType === postContentType.assignment"
            >
              <AssignmentStatus
                :post="activePost"
                :categoryId="categoryId"
                :showRetakeButton="true"
                :nextPost="nextPostData"
                @goToNextPost="goToNextPost"
              />
            </div>
            <div
              id="post-description"
              class="lessonSecondaryStyle lesson-body-description mb-5 custom-word-break custom-list-styling"
              v-html="activePost.description"
            ></div>
          </div>
        </div>
        <div
          class="mt-5 grid"
          :class="[
            (activePost.video || activePost.audio) && 'lg:px-10',
            currentPostContentType === postContentType.video ||
            currentPostContentType === postContentType.audio
              ? 'grid-cols-3'
              : 'buttons-parent-container',
          ]"
        >
          <div
            v-if="
              currentPostContentType === postContentType.video ||
              currentPostContentType === postContentType.audio
            "
            class="py-2 px-4 lg:px-0 text-center md:text-left font-medium text-gray-600 lessonSecondaryStyle lesson-count"
          >
            Lesson {{ currentPostIndex }} of {{ totalPost }}
          </div>
          <div v-else></div>
          <div class="flex justify-center items-center font-semibold">
            <button
              id="previous-lesson-button"
              :disabled="!previousPostData"
              @click="gotoPreviousPost"
              class="w-32 bg-transparent py-2 px-2 border rounded flex justify-center items-center"
              :style="
                previousPostData && { borderColor: navigationButtonColor }
              "
              :class="[
                !previousPostData
                  ? 'text-gray-500 border-gray-500'
                  : 'lessonPrimaryStyle navigation-btn text-blue-600 border-blue-600',
                currentPostContentType === postContentType.video ||
                currentPostContentType === postContentType.audio
                  ? 'mx-2'
                  : 'ml-auto',
              ]"
            >
              <span
                class="inline-flex items-center"
                :style="{
                  color: !previousPostData ? '#a0aec0' : navigationButtonColor,
                }"
              >
                <BaseIcon name="chevronleft" hwClass="w-3 h-3" />
              </span>
              <span class="pl-2 inline-flex items-center text-center"
                >Previous</span
              >
            </button>
            <div
              v-if="
                currentPostContentType !== postContentType.video &&
                currentPostContentType !== postContentType.audio
              "
              class="py-2 px-4 text-center md:text-center font-medium text-gray-600 lessonSecondaryStyle lesson-count"
            >
              Lesson {{ currentPostIndex }} of {{ totalPost }}
              <span class="capitalize">{{
                currentPostContentType !== postContentType.video &&
                currentPostContentType !== postContentType.audio
                  ? `: ${currentPostContentType}`
                  : ''
              }}</span>
            </div>
            <button
              id="next-lesson-button"
              :disabled="!nextPostData"
              @click="goToNextPost"
              class="w-32 bg-transparent py-2 px-2 border rounded flex justify-center items-center"
              :class="
                !nextPostData
                  ? 'text-gray-500 border-gray-500'
                  : 'lessonPrimaryStyle navigation-btn text-blue-600 border-blue-600'
              "
              :style="nextPostData && { borderColor: navigationButtonColor }"
            >
              <span class="pr-2 text-center">Next</span>
              <span
                class="inline-flex items-center"
                :style="{
                  color: !nextPostData ? '#a0aec0' : navigationButtonColor,
                }"
              >
                <BaseIcon name="chevronright" hwClass="w-3 h-3" />
              </span>
            </button>
          </div>
          <div
            class="post-status-fetch-message flex justify-end items-center"
            v-if="fetchingPostCompletionStatus"
          >
            Fetching post status..
          </div>
          <div
            v-else
            class="pt-2 md:pt-0 bg-transparent flex justify-end items-center"
            :disabled="disablePostCompletionButton || isAdmin"
          >
            <button
              @click="togglePostCompletion()"
              :disabled="disablePostCompletionButton || isAdmin"
              class="cursor-pointer py-2 px-2 border rounded flex justify-center items-center bg-gray-100 lessonPrimaryStyle border-none focus:ring-0"
              :class="markCompletedBtnCss"
            >
              <span
                class="inline-flex items-center"
                :style="{ color: postCompletedIcon }"
              >
                <BaseIcon name="correct" hwClass="w-4 h-4" />
              </span>
              <span class="px-2 inline-flex items-center">{{
                postCompletionButtonText
              }}</span>
            </button>
          </div>
        </div>
        <div
          v-if="activePost.video || activePost.audio"
          class="post-body mt-5"
          :class="[
            expandPost &&
              'p-5 bg-white shadow rounded lesson-body-background overflow-y-scroll',
            (activePost.video || activePost.audio) && 'lg:mx-10',
          ]"
        >
          <div
            v-if="expandPost"
            id="post-description"
            class="course-body-height lessonSecondaryStyle lesson-body-description custom-word-break"
          >
            <span
              class="custom-list-styling custom-word-break"
              v-html="activePost.description"
            ></span>
            <span
              class="text-blue-500 cursor-pointer pb-2"
              @click="expandPostDescription"
              >Show Less</span
            >
          </div>
          <span
            class="custom-list-styling custom-word-break"
            v-else
            v-html="postDescription"
          >
          </span>
        </div>
      </div>
      <div
        class="mt-4"
        :class="(activePost.video || activePost.audio) && 'lg:px-10'"
      >
        <new-york-post-queue
          :categoryTree="categoryTree"
          :previousCategory="previousCategory"
          :completed-posts="completedPostIds"
          :activePost="activePost"
          :nextCategory="nextCategory"
          :category="category"
          :categoryNavIcon="categoryNavIcon"
          :highlightBackground="highlightBackground"
          :highlight-color="highlightColor"
        />
      </div>
      <div
        class="w-full"
        v-if="activePost.commentPermission !== 'hidden'"
        :class="(activePost.video || activePost.audio) && 'lg:px-10'"
      >
        <CommentList
          :productCommentPrivacy="product.commentPrivacy"
          :postId="postId"
          :productId="productId"
          :instructor="instructor"
          :commentPermission="activePost.commentPermission"
          :pendoPayload="{ post: activePost, product, category }"
          :commentIdToScroll="commentIdToScroll"
        />
      </div>
      <div
        class="mt-4"
        :class="(activePost.video || activePost.audio) && 'lg:px-10'"
      >
        <InstructorCard :instructor="instructor" :newYorkTheme="true" />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import CategoryPostBreadcrumbs from '@/components/common/CategoryPostBreadcrumbs.vue'
import MobileNewYorkLessonTheme from './MobileNewYorkLessonTheme.vue'
import VideoPlayer from '@/components/product/VideoPlayer.vue'
import AudioPlayer from '@/components/product/AudioPlayer.vue'
import Category from '@/models/Category'
import InstructorCard from '@/components/product/InstructorCard.vue'
import QuizStatusComponent from '@/components/assessments/QuizStatusComponent.vue'
import NewYorkPostQueue from '../NewYorkPostQueue.vue'
import { MAXIMUM_CHARACTER_LENGTH_BEFORE_READMORE } from '@/helper/constants'
import UISpinner from '@/components/common/UISpinner.vue'
import { showCollapsableDescription, sliceLongText } from '@/helper'
import CategoryTreeNode, {
  CategoryTreeNodeType,
} from '@/models/CategoryTreeNode'
import {
  findNextPostId,
  findPreviousPostId,
  getCurrentPostIndex,
} from '@/helper/index'
import CommentList from '@/components/comments/CommentList.vue'
import BaseIcon from '@/components/svgicons/BaseIcon.vue'
import AssignmentStatus from '@/components/assessments/AssignmentStatus.vue'
import { PostContentType } from '@/models/Post'
import {
  trackCustomGAEvents,
  GAEventTypes,
} from '@gohighlevel/clientportal-core'

export default defineComponent({
  components: {
    CategoryPostBreadcrumbs,
    InstructorCard,
    NewYorkPostQueue,
    VideoPlayer,
    CommentList,
    QuizStatusComponent,
    UISpinner,
    MobileNewYorkLessonTheme,
    BaseIcon,
    AssignmentStatus,
    AudioPlayer,
  },
  props: {
    product: {
      type: Object,
    },
    instructor: {
      type: Object,
    },
    activePost: {
      type: Object,
    },
    category: {
      type: Object,
    },
    videoOptions: {
      type: Object,
    },
    playlist: {
      type: Array,
    },
    disableActions: {
      type: Object,
    },
    videoCompletePercentage: Number,
    postCompletionButtonText: String,
    postMarkedAsCompleted: Boolean,
    lessonButton: Object,
    isAdmin: Boolean,
    categoryTree: Array,
    fetchingPost: Boolean,
    fetchingPostCompletionStatus: Boolean,
    disablePostCompletionButton: Boolean,
    completedPostIds: {
      type: Set,
      default: new Set(),
    },
  },
  beforeMount() {
    //Redirecting user to mobile assignment route if the user loads assignment post in mobile
    if (
      this.activePost.contentType === this.postContentType.assignment &&
      this.newMobileScreens
    ) {
      this.$router.push({
        name: 'mobile-assignment',
        params: {
          ...this.$route.params,
        },
      })
    }
  },
  async mounted() {
    this.loadStylesheetTags()
    document
      .querySelector('#read-more-post')
      ?.addEventListener('click', this.expandPostDescription)
    this.isVideoProgressUpdated = false
  },
  computed: {
    currentPostContentType(): string {
      return this.activePost && this.activePost.contentType
    },
    shortendparentCategoryTitle(): string {
      return sliceLongText(this.parentCategoryTitle)
    },
    categoryTitle(): string {
      return sliceLongText(this.category.title)
    },
    postTitle(): string {
      return sliceLongText(this.activePost.title)
    },

    productId(): string {
      return this.$route.params.id
    },
    categoryId(): string {
      return this.$route.params.category_id
    },
    postId(): string {
      return this.$route.params.post_id
    },
    lessonSettings(): any {
      return this.product.customizations?.lessonSettings
    },
    templateId(): string {
      return this.$route.query.template_id
    },
    postDescription(): string {
      return this.activePost.description &&
        this.activePost.description.length >
          MAXIMUM_CHARACTER_LENGTH_BEFORE_READMORE
        ? this.derivePostDescription() +
            '.... ' +
            `<span class="text-blue-500 cursor-pointer" id="read-more-post">Read More</span>`
        : this.activePost.description
    },
    isExpandable(): boolean {
      return this.activePost.description.length > 5700 ? true : false
    },
    themeSettings(): any {
      return this.product.customizations?.lessonSettings?.themeSettings
    },
    sections(): any {
      return this.product.customizations?.lessonSettings?.sections
    },
    postCompletedIcon(): string {
      if (this.sections?.lessonBody?.button?.color) {
        return this.sections?.lessonBody?.button?.color
      } else if (this.themeSettings?.primaryStyle?.color) {
        return this.themeSettings?.primaryStyle.color
      }
      return this.postMarkedAsCompleted
        ? '#2f855a'
        : this.disablePostCompletionButton || this.isAdmin
        ? '#6b7280'
        : '#ffffff'
    },
    navigationButtonColor(): string {
      if (this.sections?.lessonBody?.navigationButton?.color) {
        return this.sections.lessonBody.navigationButton.color
      } else if (this.themeSettings?.primaryStyle?.color) {
        return this.themeSettings?.primaryStyle.color
      }
      return '#709BE0'
    },
    categoryNavIcon(): string {
      if (this.sections?.courseNavigation?.category?.color) {
        return this.sections.courseNavigation.category.color
      } else if (this.themeSettings?.secondaryStyle?.color) {
        return this.themeSettings?.secondaryStyle.color
      }
      return '#607179'
    },
    goBackIcon(): string {
      if (this.sections?.courseNavigation?.breadCrumb?.color) {
        return this.sections?.courseNavigation?.breadCrumb?.color
      } else if (this.themeSettings?.primaryStyle?.color) {
        return this.themeSettings?.primaryStyle.color
      }
      return '#111827'
    },
    highlightBackground(): string {
      if (this.sections?.courseNavigation?.highlight?.backgroundColor) {
        return this.sections?.courseNavigation?.highlight?.backgroundColor
      } else if (this.themeSettings?.primaryStyle?.color) {
        return this.themeSettings?.primaryStyle?.color
      }
      return '#709be0'
    },
    highlightColor(): string {
      if (this.sections?.courseNavigation?.highlight?.color) {
        return this.sections.courseNavigation.highlight.color
      }
      return '#ffffff'
    },
    parentCategoryTitle(): string {
      if (this.category.parentCategory) {
        const parentCategory = this.categoryTree.find(
          (node: CategoryTreeNode) => node.id === this.category.parentCategory
        )
        return parentCategory?.node.title
      }
      return null
    },
    markCompletedBtnCss() {
      if (this.disablePostCompletionButton || this.isAdmin) {
        return 'lessonPrimaryStyle lesson-body__btn bg-white text-gray-600 cursor-not-allowed'
      } else if (this.postMarkedAsCompleted) {
        return 'text-green-700'
      }
      return 'mark-as-post-completed lessonPrimaryStyle lesson-body__btn text-white'
    },
    availableCategoryTree(): any {
      return this.categoryTree.filter((node: any) => node.node.available)
    },
    availablePlayList(): Array<CategoryTreeNode> {
      return this.playlist.filter(
        (node: any) =>
          node.type === CategoryTreeNodeType.Post ||
          (node.type === CategoryTreeNodeType.Category && node.node.available)
      )
    },
    previousPostData(): any {
      return findPreviousPostId(
        this.availableCategoryTree,
        this.postId,
        this.category,
        this.availablePlayList
      )
    },
    nextPostData(): any {
      return findNextPostId(
        this.availableCategoryTree,
        this.postId,
        this.category,
        this.availablePlayList
      )
    },
    totalPost(): number {
      let parentCategory
      if (this.category.parentCategory) {
        parentCategory = this.availableCategoryTree.find(
          (node: CategoryTreeNode) => node.id === this.category.parentCategory
        )
      } else {
        parentCategory = this.availableCategoryTree.find(
          (node: CategoryTreeNode) => node.id === this.category.id
        )
      }
      return (
        parentCategory?.childs.reduce((acc, node: CategoryTreeNode) => {
          if (node.type === CategoryTreeNodeType.Category) {
            acc += node.childs.length
            return acc
          }
          return ++acc
        }, 0) || 0
      )
    },
    currentPostIndex(): number {
      return getCurrentPostIndex(this.postId, this.category, this.categoryTree)
    },
    previousCategory(): any {
      let id = this.categoryId
      if (this.category.parentCategory) {
        const parentCategory = this.availableCategoryTree.find(
          (node: CategoryTreeNode) => node.id === this.category.parentCategory
        )
        id = parentCategory?.id
      }
      const index = this.availableCategoryTree.findIndex(
        (node: CategoryTreeNode) => node.id === id
      )
      if (index === 0) {
        return null
      }
      const categories =
        this.availableCategoryTree[index - 1]?.childs?.filter(
          (node: any) =>
            node.type === CategoryTreeNodeType.Post ||
            (node.type === CategoryTreeNodeType.Category && node.node.available)
        ) || []
      if (categories[0]?.type === CategoryTreeNodeType.Category) {
        return {
          category: categories[0]?.id,
          post: categories[0]?.childs[0]?.id || null,
        }
      }
      return {
        category: this.availableCategoryTree[index - 1]?.id,
        post: categories[0]?.id || null,
      }
    },
    nextCategory(): any {
      let id = this.categoryId
      if (this.category.parentCategory) {
        const parentCategory = this.availableCategoryTree.find(
          (node: CategoryTreeNode) => node.id === this.category.parentCategory
        )
        id = parentCategory?.id
      }
      const index = this.availableCategoryTree.findIndex(
        (node: CategoryTreeNode) => node.id === id
      )
      if (index >= this.availableCategoryTree.length - 1) {
        return null
      }
      const categories =
        this.availableCategoryTree[index + 1]?.childs?.filter(
          (node: any) =>
            node.type === CategoryTreeNodeType.Post ||
            (node.type === CategoryTreeNodeType.Category && node.node.available)
        ) || []
      if (categories[0]?.type === CategoryTreeNodeType.Category) {
        return {
          category: categories[0]?.id,
          post: categories[0]?.childs[0]?.id || null,
        }
      }
      return {
        category: this.availableCategoryTree[index + 1]?.id,
        post: categories[0]?.id || null,
      }
    },
    device(): string {
      return this.$store.getters.device
    },
    newMobileScreens(): boolean {
      return this.$store.getters.newMobileScreens
    },
    isBuilder(): boolean {
      return this.$route.query.builder === 'true'
    },
    featureFlags() {
      return this.$store.state.featureFlag
    },
  },
  data() {
    return {
      parentCategory: null as Category,
      fetching: false,
      expandPost: false,
      readMorePost: null,
      showExpandIcon: false,
      expandPostData: false,
      canRetakeQuiz: false,
      post: {},
      postContentType: PostContentType,
      commentIdToScroll: this.$route.query.commentId,
    }
  },
  beforeDestroy() {
    this.storeVideoProgress()
  },
  methods: {
    loadStylesheetTags() {
      const customStyle = document.createElement('link')
      customStyle.setAttribute('rel', 'stylesheet')
      customStyle.setAttribute('vmid', 'newYorkFonts')
      customStyle.setAttribute(
        'href',
        'https://fonts.googleapis.com/css?family=Lato'
      )
      document.head.appendChild(customStyle)
    },
    updateVideoPlay() {
      this.$emit('updateVideoPlay')
    },
    storeVideoProgress() {
      this.$emit('storeVideoProgress')
    },
    getVideoTime(currentTime: Array<number>) {
      this.$emit('updateVideoTime', currentTime)
    },
    updateAudioPlay() {
      this.$emit('updateAudioPlay')
    },
    storeAudioProgress() {
      this.$emit('storeAudioProgress')
    },
    getAudioTime(currentTime: Array<number>) {
      this.$emit('updateAudioTime', currentTime)
    },
    changeQuizStatus(quizRetake: boolean) {
      this.canRetakeQuiz = quizRetake
    },
    derivePostDescription(): string {
      return showCollapsableDescription(this.activePost)
    },
    findSubstringInstances(inputString: string, substring: any) {
      return inputString.split(substring)
    },
    togglePostCompletion() {
      this.$emit('togglePostCompletion')
    },
    expandPostDescription() {
      if (!this.expandPost) {
        document
          .querySelector('#read-more-post')
          .removeEventListener('click', this.expandPostDescription)
      }
      this.expandPost = !this.expandPost
      if (!this.expandPost) {
        setTimeout(() => {
          document
            .querySelector('#read-more-post')
            .addEventListener('click', this.expandPostDescription)
        }, 500)
      }
    },
    expandPostDetails() {
      this.expandPostData = !this.expandPostData
    },
    gotoPreviousPost() {
      this.$router.push({
        name:
          this.previousPostData.contentType ===
            this.postContentType.assignment && this.newMobileScreens
            ? 'mobile-assignment'
            : 'post-overview',
        params: {
          id: this.productId,
          category_id: this.previousPostData.categoryId || this.categoryId,
          post_id: this.previousPostData.postId,
        },
        query: {
          template_id: this.templateId,
          ...this.$route.query,
        },
      })
    },
    goToNextPost() {
      trackCustomGAEvents(GAEventTypes.NEXT_LESSON, {
        postId: this.nextPostData.postId,
      })
      this.$router.push({
        name:
          this.nextPostData.contentType === this.postContentType.assignment &&
          this.newMobileScreens
            ? 'mobile-assignment'
            : 'post-overview',
        params: {
          id: this.productId,
          category_id: this.nextPostData.categoryId,
          post_id: this.nextPostData.postId,
        },
        query: {
          template_id: this.templateId,
          ...this.$route.query,
        },
      })
    },
    updateBackData(params) {
      this.$emit('updateBackData', params)
    },
  },
})
</script>
<style scoped>
.newyork-template {
  font-family: Lato;
}
.custom-word-break {
  word-break: break-word;
}

.post-body-description {
  height: 250px;
}
.expanded-post-body {
  height: 400px;
}
@media screen and (min-width: 770px) {
  .post-body-description {
    height: 600px;
  }
  .expanded-post-body {
    height: 1000px;
  }
}
@media only screen and (min-width: 700px) and (max-width: 850px) {
  #post-details-container {
    margin: 2px 4px;
  }
}
.course-body-height {
  max-height: 30rem;
}
.mark-as-post-completed {
  background-color: #709be0;
}

.assignment-button {
  background-color: #3b5998;
  font-weight: bold;
  color: white;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.26);
}

.buttons-parent-container {
  grid-template-columns: 1fr 30rem 1fr;
}
</style>
