<template>
  <div
    class="block pt-4 grid grid-cols-2 gap-3 lg:grid lg:grid-cols-3 lg:gap-4 mt-20"
  >
    <div
      class="w-full lg:w-full shadow lg:shadow-lg rounded mb-3 md:mb-0"
      v-for="i of 6"
      :key="i"
    >
      <shimmer class="w-full h-24 md:h-40 rounded lg:h-48" />
      <div class="px-5">
        <shimmer class="w-2/3 h-2 my-1 rounded-lg" />
        <shimmer class="w-full h-10 rounded-lg" />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import Shimmer from './Shimmer.vue'
export default defineComponent({
  components: {
    Shimmer,
  },
})
</script>
