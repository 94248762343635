<template>
  <div class="category-post-list w-full">
    <template :key="child.id" v-for="(child, index) in categoryTree">
      <component
        v-if="nodeIsPost(child.type) && available"
        :is="hasProductAccess ? 'router-link' : 'span'"
        tag="div"
        :to="{
          name: 'post-overview',
          params: {
            id: child.node.productId,
            category_id: child.node.categoryId,
            post_id: child.id,
          },
          query: {
            template_id: templateId,
          },
        }"
        class="flex justify-between items-center py-4 pl-6 pr-2 cursor-pointer text-sm lessonSecondaryStyle"
        :id="`post__${child.id}`"
        :class="[
          child.id === postId
            ? 'postqueue-lesson text-nova-blue-900 post-highlight'
            : 'text-nova-blue-600 hover:bg-gray-300 hover:text-gray-700 postqueue-lesson',
          categoryId && active[categoryId] === false ? 'hidden' : 'block',
        ]"
      >
        <!-- <div class="md:pr-4">{{ postIndexes.get(child.id) }}</div> -->
        <div class="flex-1 justify-center custom-word-break">
          {{ child.node.title }}
        </div>
        <div class="flex items-center justify-end">
          <div
            v-if="
              postId === child.id &&
              activePost.materials &&
              activePost.materials.length
            "
            class="px-2 flex justify-center"
            @click="showModal = true"
            :style="{ color: highlightColor }"
          >
            <BaseIcon name="docdownload" hwClass="h-3 w-3" />
          </div>
          <img
            v-if="nodeIsCompleted(child)"
            class="h-5 px-3"
            :src="checkIcon"
            alt="Completed"
          />
        </div>
      </component>
      <!-- Hiding below content to not show any content for drip -->
      <div
        :key="child.id"
        v-else-if="nodeIsPost(child.type) && !available"
        class="justify-between items-center py-4 pl-6 pr-2 text-gray-500 hidden"
      >
        <div class="md:pr-6">{{ postIndexes.get(child.id) }}</div>
        <div class="flex-1 justify-center text-base custom-word-break">
          {{ child.node.title }}
        </div>
      </div>
      <div class="category-contents" v-else :key="child.id">
        <div
          class="w-11/12 mx-3"
          :class="[level === 0 && index !== 0 && 'border-t']"
        ></div>
        <div
          v-if="child.node.available"
          class="w-full category-title text-gray-700 font-medium px-3 py-4 lessonSecondaryStyle"
          :class="[
            level === 0
              ? 'text-lg postqueue-category cursor-pointer hover:bg-gray-300 hover:text-gray-700'
              : 'text-sm postqueue-subCategory',
            active[child.node.parentCategory] === false ? 'hidden' : 'block',
          ]"
          @click="emitter.emit('updateActiveCategory', child.id)"
        >
          <span class="pr-3 text-xl font-bold" v-if="level === 0">{{
            active[child.id] === false ? '+' : '-'
          }}</span>
          <span class="custom-word-break">{{
            child.node.title.toLocaleUpperCase()
          }}</span>
        </div>
        <!-- Removing on click event for drip category to show no content -->
        <div
          v-else
          class="flex justify-between items-center category-title font-bold text-gray-500 px-3 py-4 drip-post-title"
        >
          <div class="flex items-center">
            <span class="pr-3 text-xl" v-if="level === 0">+</span>
            <span
              class="custom-word-break"
              :class="level === 0 ? 'text-lg' : 'text-sm'"
              >{{ child.node.title.toLocaleUpperCase() }}</span
            >
          </div>
          <span class="float-right text-sm">(Coming Soon)</span>
        </div>
        <NeueCategoryPostTree
          :available="child.node.available"
          :level="level + 1"
          :active="active"
          v-if="child.childs && child.childs.length"
          :categoryId="
            child.node.parentCategory
              ? child.node.parentCategory
              : child.node.id
          "
          :category-tree="child.childs"
          :isBackground="isBackground"
          :activePost="activePost"
          :post-indexes="postIndexes"
          :completed-posts="completedPosts"
          :highlightBackground="highlightBackground"
          :highlightColor="highlightColor"
          :hasProductAccess="hasProductAccess"
        />
        <div class="font-semibold px-6 py-4" v-else>No content available!</div>
        <div
          class="w-11/12 mx-3"
          :class="[
            level === 0 && index === categoryTree.length - 1 && 'border-b',
          ]"
        ></div>
      </div>
    </template>
    <post-material-modal
      v-if="hasProductAccess"
      :show-modal="showModal"
      :materials="activePost.materials"
      @close="showModal = false"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { checkIcon } from '@/helper/constants'
import PostMaterialModal from '@/components/common/PostMaterialModal.vue'
import CategoryTreeNode, {
  CategoryTreeNodeType,
} from '@/models/CategoryTreeNode'
import { checkNodeIsCompleted } from '@/helper'
import BaseIcon from '@/components/svgicons/BaseIcon.vue'

export default defineComponent({
  components: {
    PostMaterialModal,
    BaseIcon,
  },
  name: 'NeueCategoryPostTree',
  props: {
    categoryTree: {
      type: Array,
    },
    level: {
      type: Number,
      default: 0,
    },
    available: {
      type: Boolean,
      default: true,
    },
    activePost: {
      type: Object,
    },
    isBackground: {
      type: Boolean,
      default: true,
    },
    highlightBackground: {
      type: String,
    },
    completedPosts: {
      type: Set,
      default: new Set(),
    },
    postIndexes: {
      type: Map,
      default: new Map(),
    },
    active: Object,
    highlightColor: String,
    categoryId: String,
    hasProductAccess: {
      type: Boolean,
    },
  },
  data() {
    return {
      checkIcon,
      showModal: false,
    }
  },
  mounted() {
    const categoryContainer = document.querySelector(
      '#category-list'
    ) as HTMLElement
    const activePostEl = document.querySelector(
      `#post__${this.postId}`
    ) as HTMLElement
    if (activePostEl?.offsetTop > categoryContainer?.offsetHeight - 10) {
      activePostEl.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  },
  methods: {
    nodeIsPost(type: CategoryTreeNodeType) {
      return type === CategoryTreeNodeType.Post
    },
    nodeIsCompleted(entity: CategoryTreeNode): boolean {
      return checkNodeIsCompleted(this.completedPosts, entity)
    },
  },
  computed: {
    postId(): string {
      return this.$route.params.post_id
    },
    templateId(): string {
      return this.$route.query.template_id
    },
  },
})
</script>
<style scoped>
.custom-word-break {
  word-break: break-word;
}
</style>
