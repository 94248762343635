import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-48df5033"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex max-w-xs justify-between px-3 py-1 items-center p-1 border border-gray-400 rounded bg-gray-100 my-2 self-center" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = { class: "text-xm px-2" }
const _hoisted_4 = { class: "flex items-center" }
const _hoisted_5 = { class: "px-3 font-10-px self-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_BaseIcon, {
        name: "document",
        hwClass: "h-3 w-3 text-gray-500"
      }),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.trimmedFileName), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.convertFileSize(_ctx.file.size)), 1),
      _createElementVNode("div", {
        class: "flex items-center",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.downloadMaterial(_ctx.file.url, _ctx.file.title)))
      }, [
        _createVNode(_component_BaseIcon, {
          name: "download",
          class: "text-blue-500 cursor-pointer",
          hwClass: "h-4 w-4"
        })
      ])
    ])
  ]))
}