<script lang="ts" setup>
import CategorySyllabusV2 from "@/components/mobile/CategorySyllabusV2.vue";
import PostCommentsV2 from "@/components/mobile/PostCommentsV2.vue";
import TabComponentV2 from "@/components/mobile/TabComponentV2.vue";
import { CURRICULAM_COMMENTS_TAB } from "@/helper/constants";
import Category from "@/models/Category";
import Post from "@/models/Post";
import Product from "@/models/Product";
import { UIDrawer, UIDrawerContent } from "@gohighlevel/ghl-ui";
import { computed, nextTick, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
const store = useStore();
const selectedTab = ref("curriculum");
const openCurriculumComments = computed(() => store.getters.openCurriculumComments);

const props = defineProps<{
  categoryTree: any;
  totalPosts?: any;
  allCompletedPostsData: any;
  commentPermission: string;
  product: Product;
  category: Category;
  activePost: Post;
  hasProductAccess: boolean;
}>();

const isCommentVisible = computed(() => {
  return props.commentPermission === "enabled";
});

const tabs = ref<{ name: string; value: string; disabled?: boolean }[]>(CURRICULAM_COMMENTS_TAB);

function handleOpenCurriculumComments() {
  store.commit("SET_CURRICULUM_COMMENTS_OPEN", !openCurriculumComments.value);
}

function handleCommentsCurriculamTabChange(tab: string) {
  if (tab === 'comments' && !isCommentVisible.value) {
    return;
  }
  selectedTab.value = tab;
}
onMounted(() => {

  if (!isCommentVisible.value) {
    tabs.value = [tabs.value[0], { name: 'Comments', value: 'comments', disabled: true }]
  }
})
</script>

<template>
  <UIDrawer id="curriculum-comments-drawer" :show="openCurriculumComments" :placement="'bottom'"
    :displayDirective="'if'" default-height="90vh" :on-mask-click="handleOpenCurriculumComments">
    <UIDrawerContent id="drawer-component" class="p-0" :closable="false">
      <div class="w-full sticky top-0 bg-white z-30 pt-3">
        <div class="flex min-h-3 w-full flex-col items-center justify-center" @click="handleOpenCurriculumComments"
          v-swipe="{
            direction: 'down',
            callback: () => {
              nextTick(() => {
                handleOpenCurriculumComments();
              });
            },
          }">
          <div class="h-1 w-6 rounded-md border border-solid bg-gray-200"></div>
        </div>
        <TabComponentV2 :selectedTab="selectedTab" :tabOptions="tabs" @tab-change="handleCommentsCurriculamTabChange" />
      </div>
      <div class="mx-auto w-full" :style="{
        height: 'calc(100% - 95px)',
      }">
        <KeepAlive>
          <Transition name="fade">
            <CategorySyllabusV2 v-if="selectedTab === 'curriculum'" :categoryTree="categoryTree"
              :totalPosts="totalPosts || 0" :allCompletedPostsData="allCompletedPostsData"
              :hasProductAccess="hasProductAccess" />
            <PostCommentsV2 v-else :product="product" :activePost="activePost" :category="category" />
          </Transition>
        </KeepAlive>
      </div>
    </UIDrawerContent>
  </UIDrawer>
</template>

<style>
@media (max-width: 1024px) {
  .n-drawer-body-content-wrapper {
    box-sizing: border-box;
    padding-inline: 10px !important;
    padding-block: 0px !important;
  }

  .n-drawer {
    border-radius: 0 !important;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease-out;
}

.fade-enter-from,
.fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}
</style>
