<template>
  <div
    v-if="fetchingSubmission"
    class="flex justify-center items-center h-screen"
  >
    <UISpinner size="large" />
  </div>
  <div v-else>
    <div
      v-if="lastSubmission"
      class="border rounded-md submission-container mx-auto"
      :class="`border-${submissionColor}-600`"
    >
      <div class="height-fifty-percent">
        <div
          class="capitalize text-2xl p-5 height-fifty-percent flex items-center"
          :class="`text-${submissionColor}-600`"
        >
          <BaseIcon
            :name="
              lastSubmission.status === assessmentStatusOptions.passed
                ? 'checkcircle'
                : 'crosswithcircle'
            "
            hwClass="h-8 w-8"
          />
          <span class="ml-1">{{ this.lastSubmission.status }}</span>
        </div>
        <div
          class="text-center text-4xl height-fifty-percent"
          :class="`text-${submissionColor}-600`"
        >
          {{ this.lastSubmission.score }}%
        </div>
      </div>
      <div class="height-fifty-percent p-5">
        <div class="height-twenty-five-percent text-lg">
          {{ correctCount }} / {{ questionCount }} Questions answered correctly
        </div>
        <div
          v-if="lastSubmission.status === assessmentStatusOptions.passed"
          class="height-fifty-percent text-lg"
        >
          You have succesfully passed this quiz. Thank you.
        </div>
        <div class="height-fifty-percent text-lg" v-else>
          You have failed to reach the minimum score of this quiz. Please try
          again.
        </div>
        <div class="text-right pt-4 text-blue-600">
          <router-link
            tag="span"
            :to="{
              name: 'quiz-overview',
              params: {
                category_id: categoryId,
                post_id: postId,
              },
              query: {
                review: true,
              },
            }"
            class="cursor-pointer"
          >
            Review
          </router-link>
        </div>
      </div>
    </div>
    <div v-else class="text-center p-20 flex justify-center">
      <img
        height="300"
        width="300"
        src="https://staging.cdn.msgsndr.com/assets/membership/assessments-home.png"
      />
    </div>
    <router-link
      tag="div"
      :to="{
        name: `${postContentType}-overview`,
        params: {
          category_id: categoryId,
          post_id: postId,
        },
      }"
      class="flex justify-center mt-10"
    >
      <UIButton type="primary" id="quiz-status-component-button">
        {{ buttonLabel }}
      </UIButton>
    </router-link>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { AssessmentStatusService } from '@/services/'
import BaseIcon from '../svgicons/BaseIcon.vue'
import { UIButton } from '@gohighlevel/ghl-ui'
import UISpinner from '@/components/common/UISpinner.vue'
import { adminMode } from '@/helper/permission.helper'
import { AssessmentStatusOptions } from '@/models/AssessmentStatus'

export default defineComponent({
  props: {
    post: Object,
    categoryId: String,
  },
  computed: {
    submissionColor(): string {
      return this.lastSubmission.status === this.assessmentStatusOptions.passed
        ? 'green'
        : 'red'
    },
    postId(): string {
      return this.post ? this.post.id : ''
    },
    isAdmin() {
      return adminMode()
    },
    postContentType(): string {
      return this.post.contentType
    },
    buttonLabel(): string {
      return `${this.lastSubmission ? 'Retake' : 'Start'} ${
        this.contentTypeLabel[this.postContentType]
      }`
    },
  },
  data() {
    return {
      lastSubmission: null,
      questionCount: 0,
      correctCount: 0,
      incorrectCount: 0,
      fetchingSubmission: false,
      contentTypeLabel: {
        quiz: 'Quiz',
        assignment: 'Assignment',
      },
      assessmentStatusOptions: AssessmentStatusOptions,
    }
  },
  async mounted() {
    await this.getLastSubmission()
  },
  methods: {
    async getLastSubmission() {
      if (this.isAdmin) return // don't make api calls if it's admin
      this.fetchingSubmission = true
      try {
        const fetchedSubmission =
          await AssessmentStatusService.getLastSubmissionByPostId(this.postId)
        this.lastSubmission = fetchedSubmission.data.lastSubmission
        if (this.lastSubmission) {
          this.questionCount = this.lastSubmission.submission.length
          this.correctCount = this.lastSubmission.submission.filter(
            (e) => e.correctAnswerGiven
          ).length
          this.incorrectCount = this.lastSubmission.submission.filter(
            (e) => !e.correctAnswerGiven
          ).length
          this.$emit('changeQuizStatus', true)
        } else {
          this.$emit('changeQuizStatus', false)
        }
      } catch (error) {
        console.error(`Error getting last submission`, error)
      }
      this.fetchingSubmission = false
    },
  },
  components: {
    BaseIcon,
    UIButton,
    UISpinner,
  },
})
</script>
<style scoped>
.submission-container {
  height: 350px;
  width: 500px;
}

.height-fifty-percent {
  height: 50%;
}
.height-twenty-five-percent {
  height: 25%;
}

.assignment-button {
  background-color: #3b5998;
  font-weight: bold;
  color: white;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.26);
}
</style>
