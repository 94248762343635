<script lang="ts" setup>
import { sanitizeJavaScriptCode } from "@/helper";
import {
  userAvatarImage
} from "@/helper/constants";
import { truncateText } from "@/helper/filter";
import Category from "@/models/Category";
import Comment from "@/models/Comment";
import Post from "@/models/Post";
import Product from "@/models/Product";
import { Instructor } from "@/models/ProductCustomization";
import { CommentService, getUserId } from "@/services";
import {
  Edit01Icon,
  FlipBackwardIcon,
  FlipForwardIcon,
  Globe01Icon,
  Lock01Icon,
  Trash01Icon
} from "@gohighlevel/ghl-icons/24/outline";
import { renderIcon, UIAvatar } from "@gohighlevel/ghl-ui";
import moment from "moment";
import { computed, onMounted, ref } from "vue";
import BaseIcon from "../svgicons/BaseIcon.vue";
import DeleteModalV2 from "./DeleteModalV2.vue";
import DrawerMenuV2 from "./DrawerMenuV2.vue";
import UISpinner from "../common/UISpinner.vue";
import CommentShimmerV2 from "../shimmer/mobile/CommentShimmerV2.vue";



const commentEditOptions = ref([
  {
    label: "Edit",
    key: "edit",
    icon: renderIcon(Edit01Icon)
  },
  {
    label: "Delete",
    key: "delete",
    icon: renderIcon(Trash01Icon),
    color: 'text-red-500'
  },
]);


const commentPrivacyOptions = ref([
  {
    label: "Public",
    key: "public",
    icon: renderIcon(Globe01Icon),
  },
  {
    label: "Instructor Only",
    key: "private",
    icon: renderIcon(Lock01Icon),
  },
]);


const props = defineProps<{
  userAvatar: string;
  isRepliesAvailable: boolean;
  comment: Comment;
  instructor: Instructor | undefined;
  product: Product;
  activePost: Post;
  category: Category;
  visibility?: string;
  repliedComment?: string
  deletingComment?: boolean;
}>();

const emit = defineEmits(["updateReplyToUser", "deleteComment", "updateEditComment"]);


const fetching = ref(false);
const fetchingReplies = ref(false);
const comments = ref([]);
const commentsLimit = ref(5);
const commentsOffset = ref(0);
const hideLoadMoreComments = ref(false);
const isCommentReplyView = ref(false);
const editDrawerOpen = ref(false);
const lastCommentRequestTime = ref<string | null>(null);
const openDeleteCommentModal = ref<boolean>(false);
const currentUserId = computed(() => getUserId());

const repliedToComment = computed(() => props.comment.content);

const repliedToUserId = computed(() => props.comment.userId);

const replyToThisUser = computed(() => {
  return props.comment?.userdetails
    ? props.comment?.userdetails
    : { name: "Instructor", id: null };
});

const commentAvatar = computed(() => {
  return props.comment?.userdetails && props.comment?.userdetails?.avatar
    ? props.comment?.userdetails?.avatar
    : props.instructor && props.instructor.headshot
      ? props.instructor.headshot
      : userAvatarImage;
});

const userName = computed(() => {
  if (!props.comment?.userdetails && props.comment?.userId) return "Deleted User";
  return props.comment?.userdetails && props.comment?.userdetails?.name
    ? props.comment?.userdetails?.name
    : props.instructor && props.instructor.name
      ? props.instructor.name
      : "Instructor";
});

const productId = computed(() => props.product?.id);
const postId = computed(() => props.activePost?.id);

const commentPrivacy = computed(() =>
  commentPrivacyOptions.value.find((option) => option?.key === props.comment?.visibility)
);

function selectCommentEditOption(value: string) {
  handleEditDrawerToggle()
  if (value === "edit") {
    emit("updateEditComment", {
      id: props.comment?.id,
      content: props.comment?.content,
      name: props.comment?.userdetails ? props.comment?.userdetails.name : "",
      avatar: props.comment?.userdetails ? props.comment?.userdetails.avatar : "",
      repliedToUserId: props.comment?.repliedToUserId,
      visibility: props.visibility,
    });
  } else if (value === "delete") {
    toggleDeleteModal()
  }
}

function formatCreatedAtForComment(time) {
  return moment(time).fromNow(false);
}

function triggerReply(userDetails) {
  emit("updateReplyToUser", userDetails);
}


async function fetchComments(commentId = "", overWrite = false) {
  fetching.value = true;
  try {
    const { data } = await CommentService.findComments({
      postId: postId.value,
      productId: productId.value,
      limit: commentId ? 1 : commentsLimit.value,
      offset: commentId ? 0 : commentsOffset.value,
      order: -1,
      commentId: commentId ? commentId : "",
      commentsAfter: lastCommentRequestTime.value ?? undefined,
    });

    if (!data.comments.length) {
      hideLoadMoreComments.value = true;
      return;
    }

    comments.value = overWrite ? data.comments : comments.value.concat(data.comments);
    commentsOffset.value += commentsLimit.value;
  } catch (error) {
    console.error("Error while fetching comments --> ", error);
  } finally {
    fetching.value = false;
  }
}

function extractCommentData(comment) {
  return {
    commentId: comment.id,
    ...comment,
    parentCommentId: comment.parentCommentId ? comment.parentCommentId : comment.id,
    avatar: comment.userdetails ? comment.userdetails.avatar : "",
    name: comment.userdetails ? comment.userdetails.name : "",
    visibility: props.visibility,
  };
}

async function openCommentReplies(comment) {
  fetchingReplies.value = true;
  await fetchComments(comment.id, true);
  fetchingReplies.value = false;

  // triggerReply(extractCommentData(comment));
  isCommentReplyView.value = !isCommentReplyView.value;
}

function handleUpdateReplyToUser(comment) {
  triggerReply(extractCommentData(comment));
}

function handleUpdateEditComment(comment, visibility) {
  emit("updateEditComment", {
    id: comment.id,
    content: comment.content,
    name: comment.userdetails ? comment.userdetails.name : "",
    avatar: comment.userdetails ? comment.userdetails.avatar : "",
    repliedToUserId: comment.repliedToUserId,
    visibility: visibility,
  });
}

function handleDeleteComment(comment) {
  emit("deleteComment", comment.id);
}

function handleEditDrawerToggle() {
  editDrawerOpen.value = !editDrawerOpen.value;
}


function toggleDeleteModal() {
  openDeleteCommentModal.value = !openDeleteCommentModal.value;
}



onMounted(() => {
  fetchComments();
});
</script>

<template>
  <div id="comment" class="my-4 p-3 rounded" :class="{ 'bg-primary-25': repliedComment == comment.id }">
    <div class="flex flex-col gap-2">
      <div class="flex items-start gap-3">
        <UIAvatar :src="commentAvatar" size="large" class="min-h-10 min-w-10" />
        <div class="flex flex-col items-start w-full gap-1">
          <div class="flex items-center justify-between w-full">
            <span class="font-bold text-sm">{{ userName }}</span>
            <div class="flex items-center gap-2">
              <span class="font-normal text-xs text-[#101828]  " :class="{
                'mr-6':
                  comment?.userdetails && comment?.userdetails?.id !== currentUserId,
              }">{{ formatCreatedAtForComment(comment?.createdAt) }}</span>
              <div v-if="comment?.userdetails && comment?.userdetails?.id === currentUserId" class="pt-2">
                <BaseIcon name="dotsvertical" :style="{ height: '1rem', width: '1rem' }"
                  @click="handleEditDrawerToggle" />
              </div>
            </div>
          </div>

          <div class="comment-content font-normal text-sm   text-gray-500" v-html="comment.content" />
          <div class="flex items-center justify-between w-full">
            <span class="font-normal text-sm   text-[--accent-color] clickable" @click="() =>
              triggerReply({
                commentId: comment.id,
                ...replyToThisUser,
                parentCommentId: comment.parentCommentId
                  ? comment.parentCommentId
                  : comment.id,
                repliedToComment,
                repliedToUserId,
                visibility,
              })
              ">Reply</span>
            <span class="flex items-center gap-1 text-[--accent-color] text-xs mx-2">
              <component :is="commentPrivacy?.icon" :style="{ height: '0.75rem', width: '0.75rem ' }" />
              {{ commentPrivacy?.label }}
            </span>
          </div>
          <div v-if="fetchingReplies" class="w-full flex items-center justify-center">
            <CommentShimmerV2 :count="comment?.replies?.length || 1" />
          </div>
          <span v-if="isRepliesAvailable && !isCommentReplyView && !fetchingReplies"
            class="font-semibold text-sm text-[--accent-color] clickable flex items-center gap-2 clickable"
            @click="openCommentReplies(comment)">
            <FlipBackwardIcon class="h-5 w-5" /> View
            {{ comment?.replies?.length }} {{ comment?.replies?.length > 1 ? 'replies' : 'reply' }}
          </span>
          <span v-if="isRepliesAvailable && isCommentReplyView && !fetchingReplies"
            class="font-semibold text-sm text-[--accent-color] clickable flex items-center gap-2 clickable"
            @click="openCommentReplies(comment)">
            <FlipForwardIcon class="h-5 w-5" /> Hide replies
          </span>
          <Transition name="slide-fade">
            <div v-if="isCommentReplyView" class="w-full">
              <template v-for="reply in comment?.replies" :key="reply.id">
                <CommentV2 :userAvatar="userAvatar" :comment="reply" :instructor="instructor" :activePost="activePost"
                  :product="product" :category="category" :isRepliesAvailable="false"
                  @updateReplyToUser="() => handleUpdateReplyToUser(reply)"
                  @updateEditComment="() => handleUpdateEditComment(reply, visibility)"
                  @deleteComment="() => handleDeleteComment(reply)" :repliedComment="repliedComment" />
              </template>
            </div>
          </Transition>
        </div>
      </div>
    </div>

    <DrawerMenuV2 :placement="'bottom'" :drawerOpen="editDrawerOpen" :drawerMenuOptions="commentEditOptions"
      :handleDrawerMenuClick="selectCommentEditOption" :handleDrawerMenuToggle="handleEditDrawerToggle" />

    <DeleteModalV2 :loading="deletingComment" :showModal="openDeleteCommentModal"
      :submitCallback="() => handleDeleteComment(comment)" :modal-icon="Trash01Icon" :toggleModal="toggleDeleteModal"
      :title="'Are you sure you want to delete this comment?'"
      :description="truncateText(sanitizeJavaScriptCode(comment.content), 50)" :positiveText="'Delete'"
      :negativeText="'Cancel'" :width="400" />

  </div>
</template>

<style>
.comment-content>p {
  margin: 0 !important;
}

.slide-fade-enter-active {
  transition: all 0.1s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(20px);
  opacity: 0;
}
</style>
