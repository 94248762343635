import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5b287f6a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "heading-video-wrapper" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = { class: "mobile-video-post-body-container" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "grid grid-cols-2 items-center divide-x max-w-xl border bg-white rounded-full" }
const _hoisted_8 = { class: "relative w-full h-full block" }
const _hoisted_9 = { class: "flex items-center" }
const _hoisted_10 = { class: "relative w-full h-full block" }
const _hoisted_11 = { class: "flex items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_video_player = _resolveComponent("video-player")!
  const _component_audio_player = _resolveComponent("audio-player")!
  const _component_MobileNewYorkActionButtons = _resolveComponent("MobileNewYorkActionButtons")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createElementBlock("div", {
    id: "post-details-container mobilenewyork-template",
    class: _normalizeClass(["max-w-xl mx-auto grid grid-rows-mobile-newyork-theme-layout mobile-newyork-template", 
      _ctx.disableActions && _ctx.disableActions.pointerEvents === 'none' ? 'px-2' : ''
    ])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass(["p-4 w-full text-center text-base bg-white font-medium", [_ctx.activePost.video && 'lg:pr-10']])
      }, [
        (_ctx.parentCategoryTitle)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.parentCategoryTitle.length > _ctx.mobileHeadingLimit
              ? _ctx.parentCategoryTitle.slice(0, _ctx.mobileHeadingLimit) + '...'
              : _ctx.parentCategoryTitle) + " | ", 1))
          : _createCommentVNode("", true),
        (_ctx.category)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.category.title && _ctx.category.title.length > _ctx.mobileHeadingLimit
            ? _ctx.category.title.slice(0, _ctx.mobileHeadingLimit) + '...'
            : _ctx.category.title), 1))
          : _createCommentVNode("", true),
        _createTextVNode(" | "),
        (_ctx.activePost)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.activePost.title && _ctx.activePost.title.length > _ctx.mobileHeadingLimit
            ? _ctx.activePost.title.slice(0, _ctx.mobileHeadingLimit) + '...'
            : _ctx.activePost.title), 1))
          : _createCommentVNode("", true)
      ], 2),
      _createElementVNode("div", _hoisted_5, [
        (_ctx.activePost.video && _ctx.videoOptions)
          ? (_openBlock(), _createBlock(_component_video_player, {
              key: 0,
              id: "post-video",
              class: "items-center player-height",
              loading: _ctx.fetchingPost,
              video: _ctx.activePost && _ctx.activePost.video,
              assetUrl: _ctx.activePost && _ctx.activePost.assetUrls,
              options: _ctx.videoOptions,
              "video-width": "w-full lg:w-auto",
              trackTime: true,
              videoCompletePercentage: _ctx.videoCompletePercentage,
              "video-thumbnail": _ctx.activePost.posterImage,
              onGetVideoTime: _ctx.getVideoTime,
              onOnPause: _ctx.storeVideoProgress,
              onOnEnded: _ctx.storeVideoProgress,
              onOnPlay: _ctx.updateVideoPlay
            }, null, 8, ["loading", "video", "assetUrl", "options", "videoCompletePercentage", "video-thumbnail", "onGetVideoTime", "onOnPause", "onOnEnded", "onOnPlay"]))
          : (_ctx.activePost.audio)
            ? (_openBlock(), _createBlock(_component_audio_player, {
                key: 1,
                id: "post-audio",
                audio: _ctx.activePost.audio,
                thumbnail: _ctx.activePost.posterImage,
                post: _ctx.activePost,
                product: _ctx.product,
                onGetAudioTime: _ctx.getAudioTime,
                onOnPause: _ctx.storeAudioProgress,
                onOnEnded: _ctx.storeAudioProgress,
                onOnPlay: _ctx.updateAudioPlay
              }, null, 8, ["audio", "thumbnail", "post", "product", "onGetAudioTime", "onOnPause", "onOnEnded", "onOnPlay"]))
            : _createCommentVNode("", true)
      ]),
      (_ctx.activePost.video)
        ? (_openBlock(), _createBlock(_component_MobileNewYorkActionButtons, {
            key: 0,
            class: "border-b",
            activePost: _ctx.activePost,
            previousPostData: _ctx.previousPostData,
            nextPostData: _ctx.nextPostData,
            postCompletionButtonText: _ctx.postCompletionButtonText,
            postMarkedAsCompleted: _ctx.postMarkedAsCompleted,
            fetchingPostCompletionStatus: _ctx.fetchingPostCompletionStatus,
            disablePostCompletionButton: _ctx.disablePostCompletionButton,
            isAdmin: _ctx.isAdmin,
            onGotoPreviousPost: _ctx.gotoPreviousPost,
            onGoToNextPost: _ctx.goToNextPost,
            onTogglePostCompletion: _ctx.togglePostCompletion
          }, null, 8, ["activePost", "previousPostData", "nextPostData", "postCompletionButtonText", "postMarkedAsCompleted", "fetchingPostCompletionStatus", "disablePostCompletionButton", "isAdmin", "onGotoPreviousPost", "onGoToNextPost", "onTogglePostCompletion"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", {
      ref: "postDetails",
      class: "mobile-post-details py-5 px-4 overflow-y-scroll relative",
      onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.updatePostScrollDirection && _ctx.updatePostScrollDirection(...args)))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["pb-8", [
          _ctx.expandPost && 'bg-white rounded',
          _ctx.activePost.video && 'mb-2 lg:mx-10',
        ]])
      }, [
        _createElementVNode("span", {
          class: "custom-list-styling custom-word-break",
          innerHTML: _ctx.postDescription
        }, null, 8, _hoisted_6),
        (_ctx.showFullDescription)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: "text-blue-500 cursor-pointer pb-2",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showFullDescription = false))
            }, "Show Less"))
          : (
            _ctx.activePost.description && _ctx.activePost.description.length > _ctx.textLimit
          )
            ? (_openBlock(), _createElementBlock("span", {
                key: 1,
                class: "text-blue-500 cursor-pointer pb-2",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showFullDescription = true))
              }, "Show More"))
            : _createCommentVNode("", true)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(["flex justify-center fixed left-1/2 -translate-x-1/2 transform transition ease-in-out duration-300 pb-4", [
          !_ctx.activePost.video ? 'mobile-files-comments-chip' : 'bottom-0',
          _ctx.textPostScrollDirection && _ctx.textPostScrollDirection === 'down'
            ? 'translate-y-full mfc-chip-extra-animation'
            : 'translate-y-0',
        ]])
      }, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("button", {
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.goToMaterials && _ctx.goToMaterials(...args))),
            class: _normalizeClass(["focus:bg-gray-100 focus:outline-none rounded-full py-2 px-4", _ctx.getMobileTheme.primaryText])
          }, [
            _createElementVNode("span", _hoisted_8, [
              _createElementVNode("span", _hoisted_9, [
                _createVNode(_component_BaseIcon, {
                  name: "files",
                  hwClass: "h-5 w-5"
                })
              ]),
              (_ctx.activePost.materials)
                ? (_openBlock(), _createElementBlock("span", {
                    key: 0,
                    class: _normalizeClass(["m-badge-4 ml-1 flex items-center justify-center absolute right-0 top-0 text-xxs bg-gray-100 rounded-full w-4 h-4 text-white", _ctx.getMobileTheme.secondary])
                  }, _toDisplayString(_ctx.activePost.materials.length), 3))
                : _createCommentVNode("", true)
            ])
          ], 2),
          _createElementVNode("button", {
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.goToComments && _ctx.goToComments(...args))),
            class: _normalizeClass(["focus:bg-gray-100 focus:outline-none rounded-r-full relative py-2 px-4", [
              _ctx.activePost.commentPermission === 'hidden'
                ? 'bg-gray-200 focus:bg-gray-200 text-gray-500'
                : _ctx.getMobileTheme.primaryText,
            ]])
          }, [
            _createElementVNode("span", _hoisted_10, [
              _createElementVNode("span", _hoisted_11, [
                _createVNode(_component_BaseIcon, {
                  name: "comment",
                  hwClass: "h-5 w-5"
                })
              ]),
              _createElementVNode("span", {
                class: _normalizeClass(["m-badge-4 ml-1 flex items-center justify-center absolute right-0 top-0 text-xxs bg-gray-100 rounded-full w-4 h-4 text-white", _ctx.getMobileTheme.secondary])
              }, _toDisplayString(_ctx.totalCommentCount > 99 ? '99+' : _ctx.totalCommentCount), 3)
            ])
          ], 2)
        ])
      ], 2)
    ], 512),
    (!_ctx.activePost.video)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["items-center w-full flex flex-col z-10 bg-white", [_ctx.getMobileTheme.primaryText, _ctx.isCpAppInstalled ? '' : 'pb-3']])
        }, [
          _createVNode(_component_MobileNewYorkActionButtons, {
            class: "border-t max-w-xl w-full",
            activePost: _ctx.activePost,
            previousPostData: _ctx.previousPostData,
            nextPostData: _ctx.nextPostData,
            postCompletionButtonText: _ctx.postCompletionButtonText,
            postMarkedAsCompleted: _ctx.postMarkedAsCompleted,
            fetchingPostCompletionStatus: _ctx.fetchingPostCompletionStatus,
            disablePostCompletionButton: _ctx.disablePostCompletionButton,
            isAdmin: _ctx.isAdmin,
            onGotoPreviousPost: _ctx.gotoPreviousPost,
            onGoToNextPost: _ctx.goToNextPost,
            onTogglePostCompletion: _ctx.togglePostCompletion
          }, null, 8, ["activePost", "previousPostData", "nextPostData", "postCompletionButtonText", "postMarkedAsCompleted", "fetchingPostCompletionStatus", "disablePostCompletionButton", "isAdmin", "onGotoPreviousPost", "onGoToNextPost", "onTogglePostCompletion"])
        ], 2))
      : _createCommentVNode("", true)
  ], 2))
}