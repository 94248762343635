<template>
  <div id="user-library" class="w-full">
    <Navigation
      :backCallback="active === LIBRARY_MENU_ITEMS.search ? backCallback : null"
    />
    <div class="max-w-xl mx-auto bg-white pb-16 flex flex-col overflow-y-auto">
      <CarouselShimmer v-if="fetching" />
      <div
        v-if="
          active === LIBRARY_MENU_ITEMS.allCourses &&
          !fetching &&
          isUpsellOfferAvailable
        "
        class="w-full mt-3 mb-2"
      >
        <h2 class="mx-6 mt-1 mb-3 text-lg font-bold">Featured</h2>
        <carousel :items-to-show="1.25" :wrap-around="true">
          <slide v-for="course in upsellBundles" :key="course.id">
            <UpsellBundle
              class="w-full inline-block px-3"
              v-if="course.upsellType && course.offerUpSell"
              :offer="course.offerUpSell"
              :upsellId="course.id"
              :purchasedProductIds="purchasedProductIds"
            />
          </slide>
        </carousel>
      </div>
      <div
        id="library-container"
        class="lg:w-11/12 flex-1 flex flex-col xl:w-9/12 mx-4 px-2 lg:p-4"
      >
        <div
          v-if="!isPortalActive"
          id="library-title"
          class="relative left-0 w-full text-left px-0 lg:hidden"
        >
          <h2 v-if="active === LIBRARY_MENU_ITEMS.allCourses">All Courses</h2>
          <h2 v-else-if="active === LIBRARY_MENU_ITEMS.myCourses">
            My Courses
          </h2>
        </div>
        <LibraryShimmer v-if="fetching" />
        <div
          v-if="active === LIBRARY_MENU_ITEMS.myCourses && !fetching"
          id="product-list"
          class="mb-8 lg:my-4 lg:mb-0 grid grid-cols-2 lg:grid-cols-3 justify-center gap-4 lg:gap-5"
          :class="isPortalActive && 'mt-3'"
        >
          <NewProductCard
            v-for="product in products"
            :key="product.id"
            :product-id="product.id"
            :product-title="product.title"
            :poster-image="product.posterImage"
            :product-description="product.description"
            :instructor-name="product.customizations?.instructorName"
            :instructor-headshot="product.customizations?.instructorHeadshot"
            @handleClick="openProduct(product.id)"
          />
          <div
            class="w-full text-center col-span-2 lg:col-span-3"
            v-if="products.length === 0"
          >
            No courses found!
          </div>
        </div>
        <div
          v-if="active === LIBRARY_MENU_ITEMS.allCourses && !fetching"
          id="product-list"
          class="lg:my-4 mb-8 grid grid-cols-2 lg:grid-cols-3 justify-center gap-4 lg:gap-8"
          :class="isPortalActive && 'mt-3'"
        >
          <template
            v-for="course in allCourses.slice(sliderLimit, allCourses.length)"
            :key="course.id"
          >
            <UpsellBundle
              v-if="course.upsellType && course.offerUpSell"
              :offer="course.offerUpSell"
              :upsellId="course.id"
              :purchasedProductIds="purchasedProductIds"
              :not-in-carousel="true"
            />
          </template>
          <template :key="course.id" v-for="course in allCourses">
            <NewProductCard
              v-if="!course.upsellType"
              :product-id="course.id"
              :product-title="course.title"
              :poster-image="course.posterImage"
              :product-description="course.description"
              :instructor-name="
                course.customizations && course.customizations.instructorName
              "
              :instructor-headshot="
                course.customizations &&
                course.customizations.instructorHeadshot
              "
              :show-in-library="true"
              label="in-library"
              @handleClick="openProduct(course.id)"
            />
          </template>
          <div
            class="w-full text-center col-span-2 lg:col-span-3"
            v-if="allCourses.length === 0"
          >
            No courses and offers are available!
          </div>
        </div>
        <div
          v-if="active === LIBRARY_MENU_ITEMS.search && !fetching"
          id="search-wrapper"
        >
          <UniversalSearch device="mobile" />
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import Navigation from '@/components/common/MobileNavigation.vue'
import NewProductCard from '@/components/product/NewProductCard.vue'
import UpsellBundle from '@/components/product/UpsellBundle.vue'
import LibraryShimmer from '@/components/common/LibraryShimmer.vue'
import CarouselShimmer from '@/components/common/CarouselShimmer.vue'
import { UserPurchaseService } from '@/services'
import Product from '../models/Product'
import UniversalSearch from '@/components/common/UniversalSearch.vue'
import UpsellOffer from '@/models/UpsellOffer'
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide } from 'vue3-carousel'
import { LIBRARY_MENU_ITEMS } from '@/helper/constants'
import { isClientPortal } from '@/helper'

export default defineComponent({
  components: {
    UpsellBundle,
    Navigation,
    NewProductCard,
    LibraryShimmer,
    UniversalSearch,
    CarouselShimmer,
    Carousel,
    Slide,
  },
  data() {
    return {
      products: [] as Product[],
      fetching: false,
      upsellOffers: [] as UpsellOffer[],
      allCourses: [],
      purchasedProductIds: [],
      sliderSettings: {
        arrows: false,
        dots: true,
        centerMode: true,
        centerPadding: '45px',
      },
      sliderLimit: 5,
      isUpsellOfferAvailable: false,
      LIBRARY_MENU_ITEMS,
    }
  },
  computed: {
    active() {
      return this.$store.getters.activeLibrary
    },
    upsellBundles() {
      return this.allCourses
        .slice(0, this.sliderLimit)
        .filter((course) => course.upsellType)
    },
    isPortalActive() {
      return isClientPortal()
    },
  },
  async mounted() {
    await this.fetchUserProducts()
    await this.fetchUpsellBundle()
    window.scrollTo(0, 0)
  },
  methods: {
    async fetchUserProducts() {
      this.fetching = true

      try {
        this.products = await UserPurchaseService.getAllProducts()
        this.purchasedProductIds = this.products.map((p) => p.id)
      } catch (error) {
        console.error('Error while fetching products --> ', error)
      } finally {
        this.fetching = false
      }
    },
    async fetchUpsellBundle() {
      this.fetching = true
      try {
        const upsellOffers = await UserPurchaseService.getBundleOffers()
        const set = new Set()
        this.upsellOffers = upsellOffers.filter((offer) => {
          if (!set.has(offer.upsellOfferId)) {
            set.add(offer.upsellOfferId)
            return true
          }
          return false
        })

        if (this.upsellOffers.length > 0) {
          this.isUpsellOfferAvailable = true
        }
        this.allCourses = [...this.upsellOffers, ...this.products]
      } catch (error) {
        console.error('Error while fetching upsell bundles --> ', error)
      } finally {
        this.fetching = false
      }
    },
    openProduct(productId) {
      this.$router.push({ name: 'category-list', params: { id: productId } })
    },
    updateActiveLibrary(val: string) {
      this.$store.commit('UPDATE_ACTIVE_LIBRARY', val)
    },
    backCallback() {
      this.$store.commit('UPDATE_ACTIVE_LIBRARY', LIBRARY_MENU_ITEMS.allCourses)
    },
  },
})
</script>
<style>
.mobile-upsell-slider {
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
}
.mobile-upsell-slider-item {
  scroll-snap-align: start;
}
ul.slick-dots {
  display: flex !important;
  justify-content: center;
  align-items: center;
  bottom: -10px;
}
ul.slick-dots li {
  width: auto;
  height: auto;
  padding: 0 5px;
  margin: 0px;
}

ul.slick-dots li button {
  width: auto;
  height: auto;
  padding: 0;
}

ul.slick-dots li button:before {
  width: auto;
  height: auto;
  line-height: initial;
  position: initial;
}
</style>
