import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-21819b68"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mx-2 flex justify-center items-center" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = {
  key: 0,
  class: "tooltip rounded shadow-lg p-2 ml-4 mx-w-xs -mt-10 bg-white text-blue-500"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "text-center cursor-pointer",
      disabled: !_ctx.leftClickEnabled,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.scroll('left'))),
      style: _normalizeStyle({ color: !_ctx.leftClickEnabled ? '#a0aec0' : '#709BE0' })
    }, [
      _createVNode(_component_BaseIcon, { name: "chevronleft" })
    ], 12, _hoisted_2),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categoryList, (tree) => {
      return (_openBlock(), _createElementBlock("div", {
        key: tree.id,
        class: "has-tooltip"
      }, [
        (tree.node.title.length > 15)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(tree.node.title), 1))
          : _createCommentVNode("", true),
        _createElementVNode("span", {
          class: _normalizeClass(["mx-4 cursor-pointer text-xl font-bold text-center", [
            _ctx.activeCategory === tree.id && 'text-blue-600 border-b',
            _ctx.$route.name.includes('product-overview')
              ? 'primaryStyle course-category-title'
              : '',
          ]]),
          onClick: ($event: any) => (_ctx.changeSelectCategoryIndex(tree.id)),
          style: _normalizeStyle(`borderColor: ${_ctx.categoryBorder}`)
        }, _toDisplayString(tree.node.title.length > 15
              ? `${tree.node.title.substring(0, 16).toLocaleUpperCase()}...`
              : tree.node.title.toLocaleUpperCase()), 15, _hoisted_4)
      ]))
    }), 128)),
    _createElementVNode("div", {
      class: "text-center cursor-pointer",
      disabled: !_ctx.rightClickEnabled,
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.scroll('right'))),
      style: _normalizeStyle({ color: !_ctx.rightClickEnabled ? '#a0aec0' : '#709BE0' })
    }, [
      _createVNode(_component_BaseIcon, { name: "chevronright" })
    ], 12, _hoisted_5)
  ]))
}