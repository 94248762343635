<script lang="ts" setup>
import { ref, onMounted, onUnmounted, computed } from 'vue'
import Plyr from 'plyr'
import 'plyr/dist/plyr.css'
import { replaceBucketUrlWithCdnUrl } from '@/helper/filter'
import { AUDIO_FILE_PLACEHOLDER } from '@/helper/constants'
import { useStore } from 'vuex'
import { AudioService } from '@/services'
import { isMobileTablet } from '@/helper/device_info'
const store = useStore()
const props = defineProps({
  audio: {
    type: Object,
    default: () => ({}),
  },
  loading: {
    type: Boolean,
    default: false,
  },
  thumbnail: {
    type: String,
    default: AUDIO_FILE_PLACEHOLDER,
  },
  post: {
    type: Object,
    default: () => ({}),
  },
  product: {
    type: Object,
    default: () => ({}),
  },
  maxWidth: {
    type: Boolean,
    default: true,
  },
})

const emit = defineEmits([
  'onPause',
  'onEnded',
  'onPlay',
  'onError',
  'getAudioTime',
])

const plyrAudio = ref<any>(null)
const player = ref<Plyr>(null)
const watchTime = ref(0)
const lastUpdateTime = ref(0)
const isPlaying = ref(false)

onMounted(() => {
  initPlayer()
  window.addEventListener('resize', handleResize)
})

onUnmounted(() => {
  if (player.value) player.value.destroy()
  window.removeEventListener('resize', handleResize)
})

//add a computed field for

function truncateString(str: string, num: number) {
  if (store.state.newMobileScreens) {
    num = 30
  }
  if (str.length <= num) {
    return str
  }
  return str.slice(0, num) + '...'
}

function initPlayer() {
  const audioOptions = {
    autoplay: false,
    controls: [
      'rewind',
      'play',
      'fast-forward',
      'progress',
      'current-time',
      'duration',
      'mute',
      'settings',
      'volume',
    ],
    speed: {
      selected: 1,
      options: [0.5, 1, 1.5, 1.75, 2],
    },
  }

  try {
    player.value = new Plyr(plyrAudio.value, {
      ...audioOptions,
    })
    initTriggers(player.value)
  } catch (error) {
    console.error('Error while initializing player: ', error)
  }
}

function handleResize() {
  // Reapply the necessary styles after resizing
  const progressBar = document.querySelector('.plyr__progress--played')
  const bufferBar = document.querySelector('.plyr__progress--buffer')
  const volumeButton = document.querySelector('.plyr__control--overlaid')

  if (progressBar) {
    progressBar.style.background = 'white'
  }

  if (bufferBar) {
    bufferBar.style.background = 'rgba(255, 255, 255, 0.5)'
  }

  if (volumeButton) {
    volumeButton.style.color = '#00b2ff'
  }
}

function initTriggers(playerInstance: Plyr) {
  playerInstance.on('error', (e: any) => {
    emit('onError', e)
    sendPlaybackErrorLogs(e)
    console.error('Error while initializing player...')
  })
  playerInstance.on('play', (e: any) => {
    emit('onPlay', e)
    isPlaying.value = true
    lastUpdateTime.value = playerInstance.currentTime
  })
  playerInstance.on('pause', (e: any) => {
    emit('onPause', e)
    try {
      if (isPlaying.value) {
        watchTime.value = playerInstance.currentTime - lastUpdateTime.value
        sendWatchTimeLogs()
        isPlaying.value = false
        lastUpdateTime.value = 0
      }
    } catch (error: any) {
      console.error('Error while sending watch time logs for pause: ', error)
    }
  })
  playerInstance.on('ended', (e: any) => {
    try {
      if (isPlaying.value) {
        watchTime.value = playerInstance.currentTime - lastUpdateTime.value
        sendWatchTimeLogs()
        isPlaying.value = false
        lastUpdateTime.value = 0
      }
    } catch (error: any) {
      console.error('Error while sending watch time logs for ended: ', error)
    }
    emit('onEnded', e)
  })
  playerInstance.on('timeupdate', () => {
    emit('getAudioTime', [playerInstance.currentTime, playerInstance.duration])
  })
}

async function sendWatchTimeLogs() {
  if (watchTime.value > 0) {
    const logPayload = {
      locationId: props.audio.locationId,
      watchTime: watchTime.value,
      postId: props.post.id,
      videoUrl: replaceBucketUrlWithCdnUrl(props.audio.url),
    }

    await AudioService.sendWatchtimeLogs(logPayload)
  }
}

async function sendPlaybackErrorLogs(error: any) {
  const logPayload = {
    locationId: props.audio.locationId,
    postId: props.post.id,
    audioUrl: replaceBucketUrlWithCdnUrl(props.audio.url),
    deviceName: navigator.userAgent,
    deviceType: isMobileTablet() ? 'mobile' : 'desktop',
    playbackError: error,
  }

  await AudioService.sendPlaytimeErrorLogs(logPayload)
}

defineExpose({
  player,
})
</script>

<template>
  <div
    :class="[
      'audio-player-container',
      { 'max-width-48rem': maxWidth },
      { 'max-width-67rem': !maxWidth },
    ]"
  >
    <div class="audio-content">
      <img :src="thumbnail
        ? replaceBucketUrlWithCdnUrl(thumbnail)
        : AUDIO_FILE_PLACEHOLDER
        " alt="Album Art" class="album-art" />
      <div class="audio-details">
        <span class="audio-label">Audio</span>
        <div class="audio-title">
          {{ truncateString(post.title, maxWidth ? 25 : 40) }}
        </div>
        <div class="audio-product">
          {{ truncateString(product.title, 50) }}
        </div>
      </div>
    </div>
    <audio v-if="audio" controls :src="replaceBucketUrlWithCdnUrl(audio.url)" id="plyr-audio" ref="plyrAudio"
      preload="auto" crossorigin="anonymous" class="audio-element"></audio>
  </div>
</template>

<style>
.audio-player-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
  padding: 1.25rem;
  margin: 0 auto;
  color: white;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.max-width-48rem {
  max-width: 48rem;
}

.max-width-67rem {
  max-width: 67rem;
}

.audio-content {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.audio-player-container .plyr__controls .plyr__controls__item {
  color: white;
}

.audio-player-container .plyr__progress--buffer,
.audio-player-container .plyr__progress--played {
  background: white;
}

.audio-player-container .plyr__progress--buffer {
  background: rgba(255, 255, 255, 0.5);
}

.audio-player-container .plyr__control--overlaid {
  color: #00b2ff;
}

.audio-player-container .plyr--audio .plyr__controls {
  color: white;
  background-color: black !important;
}

.album-art {
  width: 100%;
  height: auto;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}

.audio-details {
  display: flex;
  flex-direction: column;
  justify-content: end;
  width: 100%;
  overflow: hidden;
  padding-top: 50px;
}

.audio-label {
  font-size: 16px;
  font-weight: 500;
}

.audio-title {
  font-size: 30px;
  font-weight: 500;
}

.audio-product {
  font-size: 20px;
  font-weight: 500;
}

.audio-element {
  width: 100%;
}

.plyr__menu__container {
  z-index: 12 !important;
}

@media (min-width: 768px) {
  .audio-details {
    padding-top: 0;
    justify-content: start;
  }

  .audio-content {
    flex-direction: row;
  }

  .album-art {
    width: 150px;
    height: 150px;
    margin-right: 1rem;
    margin-bottom: 0;
  }

  .audio-details {
    flex: 1;
    justify-content: end;
  }

  .audio-label,
  .audio-title,
  .audio-product {
    padding-left: 0.5rem;
  }
}

@media (max-width: 768px) {
  .audio-details {
    justify-content: start;
  }

  .audio-details {
    padding-top: 0;
  }

  .audio-title {
    font-size: 18px;
    margin-bottom: 0.25rem;
  }

  .audio-product {
    font-size: 16px;
  }

  .audio-label,
  .audio-title,
  .audio-product {
    padding-left: 0.5rem;
  }

  .album-art {
    width: 80px;
    height: 80px;
    margin-right: 0.5rem;
  }

  .audio-player-container .plyr--audio .plyr__controls {
    color: white;
    background-color: black !important;
    padding: 1px !important;
  }
}

@media (max-width: 480px) {
  .album-art {
    width: 80px;
    height: 80px;
  }

  .audio-title {
    font-size: 16px;
  }

  .audio-product {
    font-size: 14px;
  }

  .audio-element {
    margin-top: 0.25rem;
  }

  .audio-player-container .plyr--audio .plyr__controls {
    color: white;
    background-color: black !important;
    padding: 1px !important;
  }
}

.audio-player-container .plyr--audio {
  width: 100%;
}

.audio-player-container .plyr__volume input {
  display: none;
}

.audio-player-container .plyr__volume:hover input {
  display: block;
  position: absolute;
  left: -106%;
  top: -174%;
  transform: rotate(270deg);
  padding: 5px;
  border-radius: 0px;
  width: 100px;
  height: 22px;
}


.audio-player-container .plyr__menu__container [role='menu'] {
  max-height: 5rem;
  overflow-y: scroll;
}
</style>
