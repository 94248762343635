import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-540c0454"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("img", {
    class: "user-avatar rounded-full",
    style: _normalizeStyle({
      minWidth: _ctx.overWriteMinLimit ? '' : '32px',
      minHeight: _ctx.overWriteMinLimit ? '' : '32px',
    }),
    src: _ctx.replaceBucketUrlWithCdnUrl(_ctx.src),
    alt: "user avatar"
  }, null, 12, _hoisted_1))
}