<template>
  <div
    class="category-list-item rounded lg:my-4 p-4 lg:p-2 items-center"
    :class="
      newMobileScreens
        ? 'bg-category-bg shadow'
        : 'bg-white grid grid-cols-4 gap-4'
    "
  >
    <div
      class="col-span-1 mb-2 lg:mb-0"
      v-if="device === 'desktop' || isBuilder || !newMobileScreens"
    >
      <img
        class="category-image w-40 h-auto"
        :src="replaceBucketUrlWithCdnUrl(category.posterImage) || defaultPosterImage"
        alt="Category"
      />
    </div>
    <div class="mb-3 lg:mb-0 lg:p-2 col-span-2">
      <div class="flex lg:block justify-between items-center">
        <p
          v-if="notAvailable"
          class="category-title font-semibold drip-category-title"
          :class="
            newMobileScreens
              ? 'text-base text-gray-500 my-0'
              : 'text-lg text-current'
          "
        >
          {{
            category.title && category.title.length > headingLimit
              ? category.title.slice(0, headingLimit) + '...'
              : category.title
          }}
          <span class="lg:float-right inline-block lg:inline"
            >(Coming Soon)</span
          >
        </p>
        <p
          v-else
          class="category-title font-semibold"
          :class="
            newMobileScreens
              ? 'text-base text-gray-700 my-0'
              : 'text-lg text-current'
          "
        >
          {{
            category.title && category.title.length > headingLimit
              ? category.title.slice(0, headingLimit) + '...'
              : category.title
          }}
        </p>
        <span
          v-if="newMobileScreens"
          :class="notAvailable ? 'text-gray-500' : 'text-gray-700'"
        >
          <BaseIcon name="chevronright" hwClass="w-4 h-5" />
        </span>
      </div>
      <p
        :class="[
          newMobileScreens
            ? 'text-gray-600 my-1'
            : 'category-description text-base',
          'whitespace-pre-line',
        ]"
      >
        {{ category.description }}
      </p>
    </div>
    <div class="col-span-1">
      <!-- Need to change below color to theme color -->
      <Progress
        :percent-completed="progress"
        :progress-color="newMobileScreens ? getMobileTheme.primary : ''"
      />
    </div>
    <div
      v-if="newMobileScreens"
      class="flex mt-2 text-gray-700 text-xs justify-end"
    >
      {{ categoryProgressCount ? categoryProgressCount.completedPosts : 0 }}/{{
        categoryProgressCount ? categoryProgressCount.totalPosts : 0
      }}
      Completed
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import Progress from '@/components/common/Progress.vue'
import {
  defaultPosterImage,
  MOBILE_CATEGORY_ITEM_HEADING_MAX_LENGTH,
} from '../../helper/constants'
import { getMobileTheme } from '@/helper'
import BaseIcon from '@/components/svgicons/BaseIcon.vue'

export default defineComponent({
  props: {
    category: {
      type: Object,
      required: true,
    },
    progress: {
      type: Number,
    },
    notAvailable: {
      type: Boolean,
      default: false,
    },
    categoryProgressCount: {
      type: Object,
    },
  },
  components: {
    Progress,
    BaseIcon,
  },
  computed: {
    device(): string {
      return this.$store.getters.device
    },
    newMobileScreens(): boolean {
      return this.$store.getters.newMobileScreens
    },
    isBuilder(): boolean {
      return this.$route.query.builder === 'true'
    },
    getMobileTheme() {
      return getMobileTheme()
    },
  },
  data() {
    return {
      defaultPosterImage,
      headingLimit: MOBILE_CATEGORY_ITEM_HEADING_MAX_LENGTH,
    }
  },
})
</script>
