<template>
  <CategoryShimmer v-if="fetching" :hide-extra="newMobileScreens" />
  <div class="category-details-container max-w-xl lg:max-w-6xl w-full mx-auto px-4 lg:px-0"
    :class="isPortalNavbar ? 'pt-20' : newMobileScreens ? 'pb-16' : 'my-4'" v-else>
    <CategoryPostBreadcrumbs v-if="device === 'desktop' || isBuilder || !newMobileScreens" :product-id="productId"
      :product-title="product && product.title" :category-id="categoryId"
      :category-title="category && category.title" />
    <div class="category-details mt-4 sm:block lg:grid grid-cols-3 gap-4">
      <div class="category-post-list-container col-span-2">
        <CategoryPostList :product-id="productId" :categories="categories" :parent-category="category.parentCategory"
          :completed-posts="postIdsCompleted" :completed-categories="categoryIdsCompleted"
          :hasProductAccess="hasProductAccess" />
      </div>
      <div v-if="!newMobileScreens" class="lesson-stats-instructor-container col-span-1">
        <LessonsStats :completed="completedPosts" :total="totalPosts" :progress-color="getMobileTheme.primary"
          class="mb-3" />
        <InstructorCard :instructor="instructor" />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, toRaw } from 'vue'
import CategoryPostBreadcrumbs from '@/components/common/CategoryPostBreadcrumbs.vue'
import LessonsStats from '@/components/product/LessonsStats.vue'
import InstructorCard from '@/components/product/InstructorCard.vue'
import CategoryPostList from '@/components/product/CategoryPostList.vue'
import Category, { CategoryVisibility } from '../models/Category'
import { PostService, UserPurchaseService } from '../services'
import { getMobileTheme, isClientPortal } from '../helper'
import { LEVELUP_LOCATION_IDS, SOURCES } from '../helper/constants'
import CategoryShimmer from '@/components/common/CategoryShimmer.vue'

export default defineComponent({
  props: {
    product: {
      type: Object,
    },
    instructor: {
      type: Object,
    },
    allCompletedPostsData: {
      type: Object,
      default: null,
    },
  },
  components: {
    CategoryPostBreadcrumbs,
    LessonsStats,
    InstructorCard,
    CategoryPostList,
    CategoryShimmer,
  },
  computed: {
    productId(): string {
      return this.$route.params.id
    },
    categoryId(): string {
      return this.$route.params.category_id
    },
    device(): string {
      return this.$store.getters.device
    },
    newMobileScreens(): boolean {
      return this.$store.getters.newMobileScreens
    },
    isBuilder(): boolean {
      return this.$route.query.builder === 'true'
    },
    postIdsCompleted(): Set<string> {
      return this.allCompletedPostsData?.completedPostIds
        ? this.allCompletedPostsData?.completedPostIds
        : new Set()
    },
    hasProductAccess(): boolean {
      return toRaw(this.product)?._data?.hasProductAccess
    },
    isPortalActive() {
      return isClientPortal()
    },
    isPortalNavbar(): boolean {
      return (
        isClientPortal() &&
        !this.$store.getters.newMobileScreens &&
        this.source !== SOURCES.communities
      )
    },
    locationId(): string {
      return this.$store.getters.locationId;
    },
    isLevelUpItem(): boolean {
      return LEVELUP_LOCATION_IDS.includes(this.locationId);
    },
    getMobileTheme() {
      return getMobileTheme()
    },
  },
  data() {
    return {
      category: {} as Category,
      categories: [] as Category[],
      completedPosts: 0,
      categoryIdsCompleted: new Set() as Set<string>,
      totalPosts: 0,
      fetching: false,
      source: this.$route.query.source,
    }
  },
  async mounted() {
    if (this.isLevelUpItem) {
      return this.$router.push({
        name: 'category-list-levelup',
        params: { id: this.productId },
        query: { category_id: this.$route.params?.category_id, selected_tab: 'syllabus' }
      })
    }
    this.fetchCategory(this.categoryId)
    await this.fetchCompletionData()

    this.$emit('updateBackData', {
      name: 'category-list',
      params: { id: this.productId },
    })
  },
  methods: {
    async fetchCategory(categoryId: string) {
      this.fetching = true
      // this.category = await CategoryService.findById(categoryId, {
      //   visibility: CategoryVisibility.published,
      //   published_posts: true
      // })

      this.category = await UserPurchaseService.getCategory(categoryId, {
        product_id: this.productId,
        visibility: CategoryVisibility.published,
        published_posts: true,
      })

      // const subCategories = await CategoryService.findAll({
      //   parent_category: this.categoryId,
      //   visibility: CategoryVisibility.published,
      //   published_posts: true
      // })
      const subCategories = await UserPurchaseService.getCategories({
        product_id: this.productId,
        parent_category: this.categoryId,
      })

      this.fetching = false

      this.categories = [this.category, ...subCategories]
    },
    async fetchCompletionData() {
      this.fetchCompletedPosts()
      await this.fetchNoOfPublishedPosts()
    },
    fetchCompletedPosts() {
      this.categoryIdsCompleted = new Set(
        this.categories.reduce((acc, categoryElem) => {
          const { posts } = categoryElem
          const categoryPostIds = posts.map((e) => e.id)
          const completedPostLengthInCategory = categoryPostIds.filter(
            (element) => this.postIdsCompleted?.has(element)
          ).length
          if (
            categoryPostIds.length > 0 &&
            completedPostLengthInCategory === categoryPostIds.length
          ) {
            acc.push(categoryElem.id)
          }
          return acc
        }, [])
      )

      this.completedPosts = this.postIdsCompleted?.size
    },
    async fetchNoOfPublishedPosts() {
      const publishedResp = await PostService.noOfPublishedPosts({
        product_id: this.productId,
      })

      this.totalPosts = publishedResp.count
    },
  },
  watch: {
    categoryId(newValue, oldValue) {
      if (newValue && newValue !== oldValue) {
        this.fetchCategory(newValue)
      }
    },
  },
})
</script>
