import ProductServiceCls from './ProductService'
import CategoryServiceCls from './CategoryService'
// import OfferServiceCls from './OfferService'
import PostServiceCls from './PostService'
import UserPostCompletionServiceCls from './UserPostCompletionService'
import UserPurchaseServiceCls from './UserPurchaseService'
import UserProductTrackingServiceCls from './UserProductTrackingService'
import UserPostTrackingServiceCls from './UserPostTrackingService'
import VideoServiceCls from './VideoService'
import AudioServiceCls from './AudioService'
// import PostMaterialServiceCls from './PostMaterialService'
// import UpsellOfferServiceCls from './UpsellOfferService'
// import VideoServiceCls from './VideoService'
// import OfferCustomizationServiceCls from './OfferCustomizationService'
// import ProductCustomizationCls from './ProductCustomizationService'
import MediaUploadServiceCls from './MediaUploadService'
import UniversalSearchServiceCls from './UniversalSearchService'
import CommentServiceCls from './CommentService'
import QuestionServiceCls from './QuestionService'
import QuizServiceCls from './QuizService'
import AssessmentStatusServiceCls from './AssessmentStatusService'
import AssignmentServiceCls from './AssignmentService'

let ProductService: ProductServiceCls
let CategoryService: CategoryServiceCls
// let OfferService: OfferServiceCls
let PostService: PostServiceCls
// let PostMaterialService: PostMaterialServiceCls
// let UpsellOfferService: UpsellOfferServiceCls
// let VideoService: VideoServiceCls
let MediaUploadService: MediaUploadServiceCls
// let OfferCustomizationService: OfferCustomizationServiceCls
// let ProductCustomizationService: ProductCustomizationCls
let UserPostCompletionService: UserPostCompletionServiceCls
let UserPurchaseService: UserPurchaseServiceCls
let UserProductTrackingService: UserProductTrackingServiceCls
let VideoService: VideoServiceCls
let UniversalSearchService: UniversalSearchServiceCls
let UserPostTrackingService: UserPostTrackingServiceCls
let CommentService: CommentServiceCls
let QuestionService: QuestionServiceCls
let QuizService: QuizServiceCls
let AssessmentStatusService: AssessmentStatusServiceCls
let AssignmentService: AssignmentServiceCls
let AudioService: AudioServiceCls

let userId: string
let contactId: string
let featureFlag: any

export function initialize(locationId: string) {
  ProductService = new ProductServiceCls(locationId)
  CategoryService = new CategoryServiceCls(locationId)
  // OfferService = new OfferServiceCls(locationId)
  PostService = new PostServiceCls(locationId)
  UserPostCompletionService = new UserPostCompletionServiceCls(locationId)
  UserPurchaseService = new UserPurchaseServiceCls(locationId)
  UserProductTrackingService = new UserProductTrackingServiceCls(locationId)
  // PostMaterialService = new PostMaterialServiceCls(locationId)
  // UpsellOfferService = new UpsellOfferServiceCls(locationId)
  // VideoService = new VideoServiceCls(locationId)
  MediaUploadService = new MediaUploadServiceCls(locationId)
  VideoService = new VideoServiceCls(locationId)
  UniversalSearchService = new UniversalSearchServiceCls(locationId)
  UserPostTrackingService = new UserPostTrackingServiceCls(locationId)
  // OfferCustomizationService = new OfferCustomizationServiceCls(locationId)
  // ProductCustomizationService = new ProductCustomizationCls(locationId)
  CommentService = new CommentServiceCls(locationId)
  QuestionService = new QuestionServiceCls(locationId)
  QuizService = new QuizServiceCls(locationId)
  AssessmentStatusService = new AssessmentStatusServiceCls(locationId)
  AssignmentService = new AssignmentServiceCls(locationId)
  AudioService = new AudioServiceCls(locationId)
}

export function saveUserId(_userId: string) {
  userId = _userId
}

export function getUserId() {
  return userId
}

export function saveContactId(_contactId: string) {
  contactId = _contactId
}

export function saveFeatureFlag(flag: any) {
  featureFlag = flag
}

export function getFeatureFlag() {
  return featureFlag
}

export function getContactId() {
  return contactId
}

export {
  ProductService,
  CategoryService,
  PostService,
  UserPostCompletionService,
  UserPostTrackingService,
  UserPurchaseService,
  UserProductTrackingService,
  // OfferService,
  // PostMaterialService,
  // UpsellOfferService,
  // VideoService,
  MediaUploadService,
  VideoService,
  UniversalSearchService,
  // OfferCustomizationService,
  // ProductCustomizationService,
  CommentService,
  QuestionService,
  QuizService,
  AssessmentStatusService,
  AssignmentService,
  AudioService,
}
