import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-26f9731c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "playlist-queue-container h-full bg-gray-800 mt-3 md:mt-0 postqueue-container" }
const _hoisted_2 = { class: "playlist-title font-semibold text-sm" }
const _hoisted_3 = { class: "playlist-length text-sm" }
const _hoisted_4 = {
  ref: "postQueue",
  class: "playlist-queue overflow-auto flex-1"
}
const _hoisted_5 = ["id"]
const _hoisted_6 = ["src"]
const _hoisted_7 = ["src"]
const _hoisted_8 = {
  key: 0,
  class: "next-category-button-container bg-gray-800 p-3 justify-center flex h-16"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["playlist-info flex justify-between text-white p-5 w-full", 
        _ctx.$route.name.includes('post-overview')
          ? 'lessonPrimaryStyle postqueue-category'
          : ''
      ])
    }, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.categoryTitle), 1),
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.playlist.length) + " Lesson" + _toDisplayString(_ctx.playlist.length > 1 ? 's' : ''), 1)
    ], 2),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.playlist, (categoryTreeNode, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "w-full",
          ref_for: true,
          ref: "postQueueItem",
          key: categoryTreeNode.id,
          id: categoryTreeNode.id
        }, [
          _createVNode(_component_router_link, {
            tag: "div",
            class: _normalizeClass(["playlist-item p-2 w-full flex items-center cursor-pointer", 
            _ctx.activePost === categoryTreeNode.id
              ? 'bg-gray-700 post-highlight'
              : ''
          ]),
            to: {
            name: 'post-overview',
            params: _ctx.getRouteParams(categoryTreeNode),
          }
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", {
                class: _normalizeClass(["playlist-item-count mr-2 text-sm postqueue-lesson", 
              _ctx.activePost === categoryTreeNode.id
                ? 'text-white post-highlight'
                : 'text-white'
            ])
              }, _toDisplayString(index + 1), 3),
              _createElementVNode("img", {
                class: "playlist-item-image object-contain h-16 w-24",
                src: _ctx.replaceBucketUrlWithCdnUrl(_ctx.getPosterImage(categoryTreeNode)),
                alt: "Post"
              }, null, 8, _hoisted_6),
              _createElementVNode("span", {
                class: _normalizeClass(["playlist-item-title flex-1 text-white text-sm pl-2 postqueue-lesson", 
              _ctx.activePost === categoryTreeNode.id
                ? 'text-white post-highlight'
                : 'text-white'
            ])
              }, _toDisplayString(categoryTreeNode.node.title), 3),
              (_ctx.nodeIsCompleted(categoryTreeNode))
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    class: "h-5",
                    src: _ctx.checkIcon,
                    alt: "Completed"
                  }, null, 8, _hoisted_7))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1032, ["class", "to"])
        ], 8, _hoisted_5))
      }), 128))
    ], 512),
    (_ctx.nextCategory)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createVNode(_component_router_link, {
            id: "next-category-button",
            to: {
          name: 'category-overview',
          params: { id: _ctx.productId, category_id: _ctx.nextCategory },
        },
            tag: "button",
            class: "rounded px-4 md:px-5 xl:px-4 py-3 md:py-4 xl:py-3 bg-gray-700 hover:bg-gray-600 text-sm text-white font-semibold leading-tight shadow-md next-category__btn"
          }, {
            default: _withCtx(() => [
              _createTextVNode("Next Category")
            ]),
            _: 1
          }, 8, ["to"])
        ]))
      : _createCommentVNode("", true)
  ]))
}