<template>
  <div>
    <!-- prettier-ignore-->
    <div
      class="h-auto bg-cover bg-no-repeat bg-center bg-gray-300 w-full flex justify-center items-center"
      :class="heroSection.alignment"
      id="banner"
      :style="{ backgroundImage: `url(${replaceBucketUrlWithCdnUrl(heroSection.background)})`}"
    >
      <div
        :style="`background-color: ${heroSection.overlayColor}`"
        class="overlay"
      ></div>
      <div
        id="hero-section"
        class="xl:px-24 lg:x-16 md:px-12 px-6 w-full"
        :class="heroSection.spacing"
      >
        <div id="product-title" class="text-white font-bold text-4xl">
          {{ product.title || 'Fetching Product..' }}
        </div>
        <div
          id="product-description"
          v-if="product.description"
          class="text-white text-xl py-2"
        >
          {{ product.description }}
        </div>
        <div id="product-status" class="mt-8">
          <div
            id="completion-message"
            class="font-semibold text-2xl text-white"
            v-if="courseCompleted"
          >
            Congrats!! You have completed this course!
          </div>
          <div
            v-if="!courseCompleted && !(nextPost && nextPost.id) && hasProductAccess"
            id="partial-completion-message"
            class="font-semibold text-xl text-white"
          >
            You seem to have completed all available contents!!
          </div>
          <button
            id="load-next-post"
            v-if="nextPost && nextPost.id && hasProductAccess"
            @click="handleNextPostClick()"
            class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none transition duration-150 ease-in-out"
          >
          {{ buttonText }} Course
          </button>
          <div v-if="!hasProductAccess" class="text-center text-white flex flex-col items-center justify-center">
            <Lock01Icon class="h-20 w-20"/>
            <UITextLgMedium>
              {{ productAccessMessage }}
            </UITextLgMedium>
          </div>
        </div>
      </div>
    </div>
    <!-- content -->
    <div
      id="product-details-container"
      class="max-w-6xl mx-auto grid md:grid-cols-5 gap-4 my-10"
    >
      <div class="category-post-list-container md:col-span-3">
        <!-- <CategoryPostList :categories="categories" :completed-posts="postIdsCompleted" /> -->
        <CategoryPostTree
          :level="0"
          :category-tree="categoryTree"
          :completed-posts="postIdsCompleted"
          :completed-categories="categoryIdsCompleted"
          :hasProductAccess="hasProductAccess"
        />
      </div>
      <div class="lesson-stats-instructor-container md:col-span-2">
        <LessonsStats
          :completed="completedPosts"
          :total="totalPosts"
          class="mb-3"
        />
        <InstructorCard :instructor="instructor" />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, toRaw } from 'vue'
import CategoryPostTree from '@/components/product/CategoryPostTree.vue'
import LessonsStats from '@/components/product/LessonsStats.vue'
import InstructorCard from '@/components/product/InstructorCard.vue'
import { Lock01Icon } from '@gohighlevel/ghl-icons/24/outline'
import { UITextLgMedium } from '@gohighlevel/ghl-ui'
import { getProductAccessMessage } from '@/helper/offerAccess'

export default defineComponent({
  components: {
    CategoryPostTree,
    LessonsStats,
    InstructorCard,
    Lock01Icon,
    UITextLgMedium
  },
  props: {
    heroSection: {
      type: Object,
    },
    product: {
      type: Object,
    },
    instructor: {
      type: Object,
    },
    categoryTree: {
      type: Array,
    },
    nextPost: {
      type: Object,
    },
    completedPosts: Number,
    totalPosts: Number,
    postIdsCompleted: Set,
    categoryIdsCompleted: Set,
    courseCompleted: Boolean,
    productStarted: Boolean,
    hasProductAccess: Boolean
  },
  methods: {
    handleNextPostClick() {
      this.$emit('goToNextPost')
    },
  },
  computed: {
    productAccessMessage() {
      return getProductAccessMessage(this.hasProductAccess, this.product)
    },
    buttonText() {
      if (this.productStarted) {
        if (this.totalPosts === this.completedPosts) return 'Go to'
        else return 'Resume'
      } else return 'Start'
    },
  }
})
</script>
<style scoped>
#banner {
  min-height: calc((100vw / 4.57));
  z-index: auto;
}
</style>
