<template>
  <div class="post-list-item py-4 justify-between items-center cursor-pointer" :class="newMobileScreens
      ? 'px-4 my-2 rounded-lg shadow bg-category-bg flex w-full'
      : 'flex'
    " @click="openPost">
    <div class="flex items-center">
      <img v-if="device === 'desktop' || isBuilder || !newMobileScreens" class="w-32 post-image"
        :src="replaceBucketUrlWithCdnUrl(posterImage)" />
      <span class="items-center" :class="[
        getMobileTheme.primaryText,
        newMobileScreens && !isBuilder ? 'flex' : 'block mx-5',
      ]">
        <BaseIcon name="assessments" v-if="showAssessmentIcon" hwClass="w-3 h-3" />
        <span class="inline-flex items-center" v-else-if="newMobileScreens && !isBuilder"
          :class="getMobileTheme.primaryText">
          <BaseIcon name="play" hwClass="w-3 h-3" />
        </span>
        <div v-if="available" class="  post-title flex-1 text-lg hover:text-blue-600 hover:underline" :class="[
          (device === 'desktop' || isBuilder || !newMobileScreens) &&
            $route.name.includes('product-overview')
            ? 'primaryStyle lesson-title'
            : 'text-gray-600',
          (device === 'desktop' || isBuilder || !newMobileScreens) &&
            $route.name.includes('category-overview')
            ? 'text-blue-600'
            : '',
          newMobileScreens ? 'mx-3' : 'mx-0',
        ]">
          {{
            post.title && post.title.length > headingLimit
              ? post.title.slice(0, headingLimit) + '...'
              : post.title
          }}
        </div>
        <div v-else class="post-title flex-1 mx-3 lg:mx-5 text-lg text-gray-500" :class="$route.name.includes('product-overview') && !newMobileScreens
            ? 'primaryStyle lesson-title'
            : ''
          ">
          {{
            post.title && post.title.length > headingLimit
              ? post.title.slice(0, headingLimit) + '...'
              : post.title
          }}
        </div>
      </span>
    </div>
    <img v-if="completed" class="h-5" :src="checkIcon" alt="Completed" />
    <div v-if="isLocked && newMobileScreens" class="h-8 w-8 bg-lock flex items-center justify-center rounded">
      <BaseIcon name="lockfilled" />
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import {
  checkIcon,
  MOBILE_CATEGORY_ITEM_HEADING_MAX_LENGTH,
} from '../../helper/constants'
import { getMobileTheme, getDefaultPosterImage } from '@/helper'
import BaseIcon from '@/components/svgicons/BaseIcon.vue'
import { PostContentType } from '@/models/Post'

export default defineComponent({
  components: {
    BaseIcon,
  },
  props: {
    post: {
      type: Object,
    },
    categoryId: {
      type: String,
    },
    completed: {
      type: Boolean,
      default: false,
    },
    available: {
      type: Boolean,
      default: true,
    },
    isLocked: {
      type: Boolean,
    },
    hasProductAccess: {
      type: Boolean,
    },
  },
  computed: {
    posterImage(): string {
      return (
        this.post.posterImage || getDefaultPosterImage(this.post.contentType)
      )
    },
    productId(): string {
      return this.post.productId
    },
    device(): string {
      return this.$store.getters.device
    },
    newMobileScreens(): boolean {
      return this.$store.getters.newMobileScreens
    },
    isBuilder(): boolean {
      return this.$route.query.builder === 'true'
    },
    currentPostContentType(): PostContentType {
      return this.post.contentType || ''
    },
    showAssessmentIcon(): boolean {
      return (
        [this.postContentType.quiz, this.postContentType.assignment].includes(
          this.currentPostContentType
        ) &&
        this.newMobileScreens &&
        !this.isBuilder
      )
    },
    getMobileTheme() {
      return getMobileTheme()
    },
  },
  data() {
    return {
      checkIcon,
      headingLimit: MOBILE_CATEGORY_ITEM_HEADING_MAX_LENGTH,
      postContentType: PostContentType,
      redirectByContentType: {
        [PostContentType.assignment]: 'mobile-assignment',
        [PostContentType.quiz]: 'mobile-quiz-overview',
        [PostContentType.video]: 'post-overview', // Posts without videos are also comes under video type.
        [PostContentType.audio]: 'post-overview',
      },
    }
  },
  methods: {
    openPost() {
      if (this.hasProductAccess === false) {
        return
      }
      if (!this.available) {
        return
      }

      this.$router.push({
        name: this.newMobileScreens
          ? this.redirectByContentType[this.post.contentType]
          : 'post-overview',
        params: {
          id: this.productId,
          category_id: this.categoryId,
          post_id: this.post.id,
        },
        query: {
          template_id: this.$route.query.template_id,
          ...this.$route.query,
        },
      })
    },
  },
})
</script>
