<script setup lang="ts">
import CategoryTreeNode from "@/models/CategoryTreeNode";
import {
  ChevronUpIcon,
  ClockStopwatchIcon,
  Key01Icon,
  Lock03Icon,
} from "@gohighlevel/ghl-icons/24/outline";

import { NODE_TYPES } from "@/helper/constants";
import { PostContentType, PostVisibility } from "@/models/Post";
import { computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { onMounted } from "vue";
import { PostService } from "@/services";
import CategorySyllabusShimmerV2 from "../shimmer/mobile/CategorySyllabusShimmerV2.vue";
import { useStore } from "vuex";

const router = useRouter();
const route = useRoute();
const store = useStore();

const {
  categoryTree,
  allCompletedPostsData,
  source,
  totalPosts,
  productId,
  hasProductAccess = true
} = defineProps<{
  categoryTree: Array<CategoryTreeNode>;
  allCompletedPostsData?: any;
  source?: string | null;
  totalPosts?: number | undefined;
  productId?: string;
  hasProductAccess?: boolean;
}>();

const categoryExpandedMap = ref<{ [key: string]: boolean }>({});
const expandAll = ref(false);
const categoryAccordianRef = ref();
const categoryLessonCountMap = ref<Map<string, number>>();
const isComponentCalledFromBundle = computed(() => source === "BUNDLE");
const fetching = ref(false);
const postLockMap = ref<{
  postUnlockedMap: { [key: string]: boolean };
  categoryUnlockedMap: { [key: string]: boolean };
}>({ postUnlockedMap: {}, categoryUnlockedMap: {} });

const isLevelUpItem = computed(() => {
  return store.state.featureFlags.newMobileUi
});

// Function to generate class object based on lesson and category
const getLessonClass = (lesson, category) => {
  if (!lesson) return {};
  return {
    "my-4": lesson.type === NODE_TYPES.POST,
    "my-8": lesson.type !== NODE_TYPES.POST,
  };
};

// Function to generate class object based on sub and category
const getSubClass = (sub) => {
  if (!sub) return {};
  return {
    "my-2": sub.type === NODE_TYPES.POST,
    "mt-8 mb-2": sub.type !== NODE_TYPES.POST,
  };
};
const getLessonCategoryDisabledClass = (category, sub, lesson) => {
  return {
    "text-gray-800": category.node.visibility !== PostVisibility.drip && sub?.node?.visibility !== PostVisibility.drip && lesson?.node?.visibility !== PostVisibility.drip,
    "text-gray-400": category.node.visibility === PostVisibility.drip || sub?.node?.visibility === PostVisibility.drip || lesson?.node?.visibility === PostVisibility.drip,
  };
};

// Helper function to check if all posts under a node are completed
const areAllPostsCompleted = (node) => {
  if (Array.isArray(node?.posts) && node?.posts.length) {
    const postIds = node.posts.map((post) => post?.id);
    return postIds.every((id) => allCompletedPostsData?.completedPostIds?.has(id));
  }
  return false;
};

function getBackgroundClassForLessonPointer(
  category,
  sub,
  lesson,
  allCompletedPostsData
) {
  let allIdsCompleted = false;
  const isPostOrCatUnlocked =
    postLockMap.value.postUnlockedMap[category?.id] ||
    postLockMap.value.categoryUnlockedMap[category?.id] ||
    postLockMap.value.postUnlockedMap[sub?.id] ||
    postLockMap.value.categoryUnlockedMap[sub?.id] ||
    postLockMap.value.postUnlockedMap[lesson?.id] ||
    postLockMap.value.categoryUnlockedMap[lesson?.id];
  const isDripLocked =
    sub?.node?.visibility === PostVisibility.drip ||
    category?.node?.visibility === PostVisibility.drip ||
    lesson?.node?.visibility === PostVisibility.drip;
  const isVisibilityLocked =
    sub?.node?.visibility === PostVisibility.locked ||
    category?.node?.visibility === PostVisibility.locked ||
    lesson?.node?.visibility === PostVisibility.locked;

  // Check if all posts under the lesson are completed
  if (lesson?.node) {
    allIdsCompleted = areAllPostsCompleted(lesson.node);
  }

  // Check if all posts under the sub-category are completed
  if (sub?.node) {
    allIdsCompleted = areAllPostsCompleted(sub.node);
  }

  if (isDripLocked) {
    return { "bg-orange-300": true }; // If it's drip content, it's not locked
  } else if (!isPostOrCatUnlocked && isVisibilityLocked) {
    return { "bg-red-300": true };   // If the post or category is not unlocked and the visibility is locked, return true
  } else if (
    allCompletedPostsData?.completedPostIds?.has(lesson?.node?.id) ||
    allCompletedPostsData?.completedPostIds?.has(sub?.node?.id) ||
    allIdsCompleted
  ) {
    return { "bg-green-300": true }; // GREEN MEANS COMPLETED
  } else {
    return { "bg-transparent": true }
  }
}

function isLocked(category, sub, lesson) {
  const isPostOrCatUnlocked =
    postLockMap.value.postUnlockedMap[sub?.id] ||
    postLockMap.value.categoryUnlockedMap[category?.id] ||
    postLockMap.value.postUnlockedMap[category?.id] ||
    postLockMap.value.categoryUnlockedMap[sub?.id] ||
    postLockMap.value.postUnlockedMap[lesson?.id] ||
    postLockMap.value.categoryUnlockedMap[lesson?.id];

  const isVisibilityLocked =
    sub?.node?.visibility === PostVisibility.locked ||
    category?.node?.visibility === PostVisibility.locked ||
    lesson?.node?.visibility === PostVisibility.locked;

  const isDripLocked =
    sub?.node?.visibility === PostVisibility.drip ||
    category?.node?.visibility === PostVisibility.drip;

  // If it's drip content, it's not locked
  if (isDripLocked) {
    return false;
  }

  // If the post or category is not unlocked and the visibility is locked, return true
  if (!isPostOrCatUnlocked && isVisibilityLocked) {
    return true;
  }

  // Otherwise, it's not locked
  return false;
}

function toggleAccordion(categoryId) {
  categoryExpandedMap.value = {
    ...categoryExpandedMap.value,
    [categoryId]: !categoryExpandedMap.value[categoryId],
  };
  if (categoryExpandedMap.value[categoryId]) {
    scrollToCategory(categoryId);
  }
}

function navigateToLesson(lesson, productId, categoryId, postId) {
  // Determine the route name based on the content type
  let routeName;
  if (lesson?.contentType === PostContentType.assignment) {
    routeName = "mobile-assignment";
  } else if (lesson?.contentType === PostContentType.quiz) {
    routeName = "mobile-quiz-overview";
  } else {
    routeName = "post-overview";
  }

  // Navigate to the determined route
  router.push({
    name: routeName,
    params: {
      id: productId,
      category_id: categoryId,
      post_id: postId,
    },
  });
}

/**
 * Handles the post details navigation based on lesson type and visibility conditions.
 *
 * @param {String} productId - The ID of the product.
 * @param {String} categoryId - The ID of the category.
 * @param {String} postId - The ID of the post.
 * @param {Object} lesson - The lesson object containing information like content type and visibility.
 * @param {Object} category - The category object containing visibility information.
 * @param {String} lessonType - The type of the lesson (e.g., post, quiz, assignment).
 */
function postDetails(productId, categoryId, postId, category, sub, lesson, lessonType) {
  // Check if the component is called from a bundle or if the lesson/category is locked or dripping, or if the lesson type isn't a post.
  if (
    isComponentCalledFromBundle.value ||
    category?.node?.visibility === PostVisibility.drip ||
    sub?.node?.visibility === PostVisibility.drip ||
    lesson?.node?.visibility === PostVisibility.drip ||
    lessonType !== NODE_TYPES.POST ||
    !hasProductAccess
  ) {
    return; // Exit early if any of the conditions are met.
  }

  // Close the curriculum comments section before navigation.
  store.commit("SET_CURRICULUM_COMMENTS_OPEN", false);

  navigateToLesson(lesson.node || sub.node, productId, categoryId, postId);
}

function handleExpandAll() {
  expandAll.value = !expandAll.value;
  categoryTree.forEach((category) => {
    categoryExpandedMap.value = {
      ...categoryExpandedMap.value,
      [category.id]: expandAll.value,
    };
  });
}
function scrollToCategory(categoryId) {
  const activeCategoryIndex = categoryTree.findIndex(
    (category) => category.id === categoryId
  );
  categoryAccordianRef.value.children?.[activeCategoryIndex]?.scrollIntoView({
    behavior: "smooth",
  });
}

/*
 @description: This function is used to get the lesson count per category map.
 @param {CategoryTreeNode[]} treeNodes - The tree nodes.
 @returns {Map<string, number>} - The lesson count per category map.
*/
function getCategoryLessonCountMap(treeNodes: CategoryTreeNode[]): Map<string, number> {
  const categoryLessonCountMap = new Map<string, number>();

  function traverseTree(node: CategoryTreeNode): number {
    let currentCategoryLessonCount = 0;

    // If the node is a POST, increment the count
    if (node.type === NODE_TYPES.POST) {
      currentCategoryLessonCount++;
    }

    // Traverse the children of the current node (if any)
    for (const child of node?.childs || []) {
      currentCategoryLessonCount += traverseTree(child);
    }

    return currentCategoryLessonCount;
  }

  // Only map the root nodes to their total lesson count
  for (const treeNode of treeNodes) {
    const totalLessonCount = traverseTree(treeNode);
    categoryLessonCountMap.set(treeNode.id, totalLessonCount);
  }

  return categoryLessonCountMap;
}


onMounted(async () => {
  const category_id = route.query?.category_id;
  if (category_id) {
    const categoryId = Array.isArray(category_id) ? category_id[0] : category_id;
    categoryExpandedMap.value = {
      ...categoryExpandedMap.value,
      [categoryId]: true,
    };
    scrollToCategory(categoryId);
  }
  categoryLessonCountMap.value = getCategoryLessonCountMap(categoryTree);
  try {
    fetching.value = true;
    postLockMap.value = await PostService.getPostLockMap(productId);
  } catch (error) {
    console.log(error);
  } finally {
    fetching.value = false;
  }
});
</script>
<template>
  <div>
    <CategorySyllabusShimmerV2 v-if="fetching" />
    <div v-else>
      <div class="w-11/12 flex flex-col items-start mx-auto my-4">
        <div class="w-full flex items-center justify-between gap-2">
          <span class="font-semibold text-[#101828] font-sans">Curriculum</span>
          <span v-show="totalPosts && categoryTree?.length"
            class="text-xs text-[--accent-color] cursor-pointer clickable" @click="handleExpandAll">{{
              !expandAll
                ? `Expand
            all sections`
                : "Collapse all sections"
            }}</span>
        </div>
        <div class="w-full flex items-center justify-start gap-2 mx-auto my-2">
          <span class="text-[#98A2B3] text-sm font-semibold">{{ totalPosts }} {{ totalPosts > 1 ? "Lessons" : "Lesson"
            }}</span>
          <div class="bg-[--accent-color] font-bold rounded w-1 h-1"></div>
          <span class="text-[#98A2B3] text-sm font-semibold">{{ categoryTree?.length }}
            {{ categoryTree?.length > 1 ? "Modules" : "Module" }}
          </span>
        </div>
      </div>

      <div class="w-11/12 mx-auto bg-white rounded-xl overflow-hidden mb-8" ref="categoryAccordianRef">
        <div v-for="category in categoryTree" :key="category.id" class="accordion-item rounded-md mt-2">
          <div @click="toggleAccordion(category.id)"
            class="flex items-center justify-between cursor-pointer m-0 p-4 border rounded-xl border-gray-100 text-gray-800 clickable"
            :class="{
              'bg-gray-100': categoryExpandedMap[category.id],
            }">
            <div class="w-11/12">
              <span class="font-medium text-sm text-[#101828] mb-2 line-clamp-3">{{ category.node.title }}
              </span>
              <span class="text-xs text-[#98A2B3]">{{ categoryLessonCountMap?.get(category.id) || 0 }}
                {{ categoryLessonCountMap?.get(category.id) > 1 ? "Lessons" : "Lesson" }}
              </span>
              <span v-if="category.node.visibility === PostVisibility.drip"
                class="font-bold text-xs text-orange-300">&nbsp;|&nbsp; Unlocks in {{ category.node?.dripDays || 0 }}
                {{ category.node?.dripDays > 1 ? "days" : "day" }}</span>
            </div>
            <ClockStopwatchIcon v-if="category.node.visibility === PostVisibility.drip"
              class="h-5 min-w-5 mx-2 text-orange-300" />
            <Lock03Icon v-else-if="isLocked(category, {}, {})" class="h-5 min-w-5 mx-2 text-red-300" />

            <ChevronUpIcon :class="{
              'rotate-180': !categoryExpandedMap[category.id],
              'rotate-0': categoryExpandedMap[category.id],
            }" class="min-w-5 h-5 transform transition-transform duration-300 text-gray-400" />
          </div>
          <transition name="slide-fade">
            <div v-show="categoryExpandedMap[category.id]">
              <div v-for="sub in category.childs" :key="sub.id" class="p-1 mb-2">
                <div class="node relative ml-4" :class="getSubClass(sub, category)" @click="
                  postDetails(
                    category.node.productId,
                    category.id,
                    sub.id,
                    category,
                    sub,
                    {},
                    sub.type
                  )
                  ">
                  <div class="flex items-start relative z-10">
                    <div
                      class="w-4 h-4 border border-gray-300 rounded-full flex-shrink-0 mt-1 flex items-center justify-center -top-12">
                      <div class="w-2 h-2 rounded-full" :class="getBackgroundClassForLessonPointer(
                        category,
                        sub,
                        {},
                        allCompletedPostsData
                      )
                        "></div>
                    </div>
                    <div class="ml-4 w-full flex items-center justify-between">
                      <div class="w-full flex flex-col items-start">
                        <span class="text-sm" :class="getLessonCategoryDisabledClass(category, sub, {})">{{
                          sub.node.title
                          }}</span>
                        <div class="flex items-center justify-between mt-1">
                          <span class="text-gray-400 text-xs">
                            <span class="uppercase">{{
                              (sub.node?.contentType || sub?.type) === PostContentType.video ? PostContentType.lesson :
                                (sub.node?.contentType || sub?.type)
                            }}</span>
                            <span v-if="sub?.node?.visibility === PostVisibility.drip"
                              class="font-bold text-xs text-orange-300">&nbsp;|&nbsp; Unlocks in {{ sub?.node?.dripDays
                                || 0 }}
                              {{ sub?.node?.dripDays > 1 ? "days" : "day" }}</span>
                            <span v-else-if="isLocked(category, sub, {})" class="font-bold text-red-300">&nbsp;|&nbsp;
                              Locked</span>
                          </span>
                        </div>
                      </div>
                      <ClockStopwatchIcon v-if="sub?.node?.visibility === PostVisibility.drip"
                        class="h-5 min-w-5 mx-2 text-orange-300" />
                      <Lock03Icon v-else-if="isLocked(category, sub, {})" class="h-5 min-w-5 text-red-300 mr-3" />
                    </div>
                  </div>
                </div>
                <div v-show="sub.childs.length" :style="{ height: `${sub.childs.length * 70}px` }">
                  <div v-for="lesson in sub.childs" :key="lesson.id" class="sub-node relative ml-12 translate-y-9"
                    :class="getLessonClass(lesson, category)" @click="
                      postDetails(
                        category.node?.productId,
                        sub.id,
                        lesson.id,
                        category,
                        sub,
                        lesson,
                        lesson.type
                      )
                      ">
                    <div class="flex items-start relative z-10">
                      <div
                        class="w-4 h-4 border border-gray-300 rounded-full flex-shrink-0 mt-1 flex items-center justify-center">
                        <div class="w-2 h-2 rounded-full" :class="getBackgroundClassForLessonPointer(
                          category,
                          sub,
                          lesson,
                          allCompletedPostsData
                        )
                          "></div>
                      </div>
                      <div class="ml-4 w-full flex items center justify-between">
                        <div class="w-full flex flex-col items-start">
                          <span class="text-sm" :class="getLessonCategoryDisabledClass(category, sub, lesson)">{{
                            lesson.node.title }}</span>
                          <div class="flex items-center justify-between mt-1">
                            <span class="text-gray-400 text-xs">
                              <span class="uppercase">{{
                                (lesson.node?.contentType || lesson?.type) === PostContentType.video ?
                                  PostContentType.lesson :
                                  (lesson.node?.contentType || lesson?.type)
                              }}</span>
                              <span v-if="isLocked(category, sub, lesson)" class="font-bold text-red-300">&nbsp;|&nbsp;
                                Locked</span>
                            </span>
                          </div>
                        </div>
                        <Lock03Icon v-if="isLocked(category, sub, lesson)" class="h-5 min-w-5 text-red-300 mr-3" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.sub-node::after {
  content: url("data:image/svg+xml, %3Csvg id='connector' width='18' height='85' viewBox='0 0 18 85' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.5 1C1.5 0.723858 1.27614 0.5 1 0.5C0.723858 0.5 0.5 0.723858 0.5 1V84C0.5 84.0167 0.500821 84.0332 0.502425 84.0496C0.510753 84.1342 0.540187 84.2126 0.585392 84.2796C0.675246 84.4126 0.827411 84.5 1 84.5H17C17.2761 84.5 17.5 84.2761 17.5 84C17.5 83.7239 17.2761 83.5 17 83.5H2C1.72386 83.5 1.5 83.2761 1.5 83V1Z' fill='%23D0D5DD'/%3E%3C/svg%3E");
  position: absolute;
  left: -1.5rem;
  top: -4.5rem;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}
</style>
