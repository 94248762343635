
import { defineComponent } from 'vue'
import VideoPlayer from '@/components/product/VideoPlayer.vue'
import AudioPlayer from '@/components/product/AudioPlayer.vue'
import Category from '@/models/Category'
import {
  MAXIMUM_CHARACTER_LENGTH_BEFORE_READMORE_MOBILE,
  MOBILE_HEADING_MAX_LENGTH,
} from '@/helper/constants'
import { getMobileTheme, isClientPortal } from '@/helper'
import CategoryTreeNode, {
  CategoryTreeNodeType,
} from '@/models/CategoryTreeNode'
import {
  findNextPostId,
  findPreviousPostId,
  getCurrentPostIndex,
} from '@/helper/index'
import MobileNewYorkActionButtons from '@/components/posts/MobileNewYorkActionButtons.vue'
import { CommentService, getUserId } from '@/services'
import BaseIcon from '@/components/svgicons/BaseIcon.vue'
import { isPwa } from '@gohighlevel/clientportal-core'
import { PostContentType } from '@/models/Post'

const __default__ = defineComponent({
  components: {
    VideoPlayer,
    MobileNewYorkActionButtons,
    BaseIcon,
    AudioPlayer,
  },
  props: {
    product: {
      type: Object,
    },
    instructor: {
      type: Object,
    },
    activePost: {
      type: Object,
    },
    category: {
      type: Object,
    },
    videoOptions: {
      type: Object,
    },
    playlist: {
      type: Array,
    },
    disableActions: {
      type: Object,
    },
    videoCompletePercentage: Number,
    postCompletionButtonText: String,
    postMarkedAsCompleted: Boolean,
    lessonButton: Object,
    isAdmin: Boolean,
    categoryTree: Array,
    fetchingPost: Boolean,
    fetchingPostCompletionStatus: Boolean,
    disablePostCompletionButton: Boolean,
    completedPostIds: {
      type: Set,
      default: new Set(),
    },
    derivePostDescription: Function,
    findSubstringInstances: Function,
    expandPostDetails: Function,
    gotoPreviousPost: Function,
    goToNextPost: Function,
  },
  async mounted() {
    if (this.activePost.contentType === this.postContentType.quiz) {
      this.$router.push({
        name: 'mobile-quiz-overview',
        params: {
          id: this.productId,
          category_id: this.categoryId,
          post_id: this.postId,
        },
        query: this.$route.query,
      })
      return
    }
    window.scrollTo(0, 0)
    await this.fetchCommentsCount()
    this.$emit('updateBackData', {
      name: 'category-overview',
      params: { id: this.productId, category_id: this.categoryId },
    })
    this.textPostScrollLocation()
    this.loadStylesheetTags()
  },
  computed: {
    productId(): string {
      return this.$route.params.id
    },
    categoryId(): string {
      return this.$route.params.category_id
    },
    postId(): string {
      return this.$route.params.post_id
    },
    lessonSettings(): any {
      return this.product.customizations?.lessonSettings
    },
    templateId(): string {
      return this.$route.query.template_id
    },
    postDescription(): string {
      return this.showFullDescription
        ? this.activePost.description
        : this.activePost.description &&
            this.activePost.description.slice(0, this.textLimit)
    },
    isExpandable(): boolean {
      return this.activePost.description &&
        this.activePost.description.length > 5700
        ? true
        : false
    },
    themeSettings(): any {
      return this.product.customizations?.lessonSettings?.themeSettings
    },
    sections(): any {
      return this.product.customizations?.lessonSettings?.sections
    },
    postCompletedIcon(): string {
      if (this.sections?.lessonBody?.button?.color) {
        return this.sections?.lessonBody?.button?.color
      } else if (this.themeSettings?.primaryStyle?.color) {
        return this.themeSettings?.primaryStyle.color
      }
      return this.postMarkedAsCompleted
        ? '#2f855a'
        : this.disablePostCompletionButton || this.isAdmin
        ? '#6b7280'
        : '#ffffff'
    },
    navigationButtonColor(): string {
      if (this.sections?.lessonBody?.navigationButton?.color) {
        return this.sections.lessonBody.navigationButton.color
      } else if (this.themeSettings?.primaryStyle?.color) {
        return this.themeSettings?.primaryStyle.color
      }
      return '#709BE0'
    },
    parentCategoryTitle(): string {
      if (this.category.parentCategory) {
        const parentCategory = this.categoryTree.find(
          (node: CategoryTreeNode) => node.id === this.category.parentCategory
        )
        return parentCategory?.node.title
      }
      return null
    },
    markCompletedBtnCss() {
      if (this.disablePostCompletionButton || this.isAdmin) {
        return 'cursor-not-allowed'
      } else if (this.postMarkedAsCompleted) {
        return 'text-green-700'
      }
      return this.getMobileTheme.primaryText
    },
    availableCategoryTree(): any {
      return this.categoryTree.filter((node: any) => node.node.available)
    },
    availablePlayList(): Array<CategoryTreeNode> {
      return this.playlist.filter(
        (node: any) =>
          node.type === CategoryTreeNodeType.Post ||
          (node.type === CategoryTreeNodeType.Category && node.node.available)
      )
    },
    previousPostData(): any {
      return findPreviousPostId(
        this.availableCategoryTree,
        this.postId,
        this.category,
        this.availablePlayList
      )
    },
    nextPostData(): any {
      return findNextPostId(
        this.availableCategoryTree,
        this.postId,
        this.category,
        this.availablePlayList
      )
    },
    postContainer() {
      return this.$refs['postDetails']
    },
    featureFlags() {
      return this.$store.state.featureFlag
    },
    isCpAppInstalled(): boolean {
      return this.$store.getters.appFlags.isCpAppInstalled
    },
    extraHeight() {
      return this.isCpAppInstalled ? '3.125rem' : '4rem'
    },
    getMobileTheme() {
      return getMobileTheme()
    },
  },
  data() {
    return {
      parentCategory: null as Category,
      expandPost: false,
      readMorePost: null,
      post: {},
      showFullDescription: false,
      totalCommentCount: 0,
      textLimit: MAXIMUM_CHARACTER_LENGTH_BEFORE_READMORE_MOBILE,
      mobileHeadingLimit: MOBILE_HEADING_MAX_LENGTH,
      textPostScrollDirection: null,
      postContentType: PostContentType,
    }
  },
  beforeDestroy() {
    this.storeVideoProgress()
  },
  methods: {
    loadStylesheetTags() {
      const customStyle = document.createElement('link')
      customStyle.setAttribute('rel', 'stylesheet')
      customStyle.setAttribute('vmid', 'newYorkFonts')
      customStyle.setAttribute(
        'href',
        'https://fonts.googleapis.com/css?family=Lato'
      )
      document.head.appendChild(customStyle)
    },
    updateVideoPlay() {
      this.$emit('updateVideoPlay')
    },
    storeVideoProgress() {
      this.$emit('storeVideoProgress')
    },
    getVideoTime(currentTime: Array<number>) {
      this.$emit('getVideoTime', currentTime)
    },
    updateAudioPlay() {
      this.$emit('updateAudioPlay')
    },
    storeAudioProgress() {
      this.$emit('storeAudioProgress')
    },
    getAudioTime(currentTime: Array<number>) {
      this.$emit('updateAudioTime', currentTime)
    },
    async fetchCommentsCount() {
      try {
        this.totalCommentCount =
          await CommentService.getTotalCommentsByUserIdForPost({
            postId: this.postId,
            userId: getUserId(),
            adminMode: this.isAdmin,
          }).then((res) => {
            if (res.data?.counts.length) {
              return res.data?.counts.reduce(
                (res, comment) => (res += comment.count),
                0
              )
            }
            return 0
          })
      } catch (error) {
        console.log('Something went wrong', error)
      }
    },
    togglePostCompletion() {
      this.$emit('togglePostCompletion')
    },
    goToMaterials() {
      this.$router.push({
        name: 'post-materials',
        params: {
          id: this.product.id,
          category_id: this.category.id,
          post_id: this.activePost.id,
        },
        query: this.$route.query,
      })
    },
    goToComments() {
      if (this.activePost.commentPermission === 'hidden') return
      this.$router.push({
        name: 'post-comments',
        params: {
          id: this.product.id,
          category_id: this.category.id,
          post_id: this.activePost.id,
        },
        query: this.$route.query,
      })
    },
    textPostScrollLocation() {
      if (!this.postContainer) return

      let lastLocation = 0
      this.postContainer.addEventListener('scroll', (event) => {
        const currentLocation = event.target.scrollTop
        if (
          currentLocation <= 0 ||
          event.target.offsetHeight + currentLocation >=
            event.target.scrollHeight
        ) {
          this.textPostScrollDirection = 'up'
          return
        }
        if (currentLocation > lastLocation) {
          this.textPostScrollDirection = 'down'
        } else {
          this.textPostScrollDirection = 'up'
        }
        lastLocation = currentLocation
      })
    },
    updatePostScrollDirection() {
      this.textPostScrollDirection = 'up'
    },
  },
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "e6118618": (_ctx.extraHeight)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__