<template>
  <div class="newyork-template">
    <div
      class="h-auto bg-cover text-white bg-no-repeat bg-center bg-gray-300 hero-image w-full flex justify-center items-center"
      id="banner" :style="!heroCustomBackground && {
        backgroundImage: `url(${replaceBucketUrlWithCdnUrl(
          heroSection.background
        )})`,
      }
        " :class="heroSection.alignment">
      <div :style="!heroCustomBackground &&
        `background-color: ${heroSection.overlayColor}`
        " class="overlay"></div>
      <div id="hero-section" class="hero-content xl:px-24 lg:x-16 md:px-12 px-6 w-full"
        :class="heroSpacing || heroSection.spacing">
        <div v-if="showLogo" class="flex justify-center items-center">
          <img v-if="brandLogoUrl" class="brand-logo h-10 w-auto lg:h-12 md:h-12"
            :src="replaceBucketUrlWithCdnUrl(brandLogoUrl)" alt="Logo" />
        </div>
        <div id="product-title" class="font-bold text-4xl" :class="$route.name.includes('product-overview')
          ? 'primaryStyle hero-title'
          : ''
          ">
          {{ product.title || 'Fetching Product..' }}
        </div>
        <div id="product-description" v-if="product.description" class="  text-xl py-2" :class="$route.name.includes('product-overview')
          ? 'secondaryStyle hero-description'
          : ''
          ">
          {{ product.description }}
        </div>
        <div id="product-status" class="mt-8" :class="heroButton && heroButton.textAlign
          ? `text-${heroButton.textAlign}`
          : 'text-center'
          ">
          <div id="completion-message" class="font-semibold text-2xl text-white" v-if="courseCompleted">
            Congrats!! You have completed this course!
          </div>
          <div v-if="
            !courseCompleted && !(nextPost && nextPost.id) && hasProductAccess
          " id="partial-completion-message" class="font-semibold text-xl text-white">
            You seem to have completed all available contents!!
          </div>
          <button id="load-next-post" v-if="nextPost && nextPost.id && hasProductAccess" @click="handleNextPostClick()"
            class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-blue-600 focus:outline-none transition duration-150 ease-in-out"
            :class="$route.name.includes('product-overview')
              ? 'primary-hero-button secondaryStyle hero-button'
              : ''
              ">
            {{ buttonText }}
          </button>
          <div v-if="!hasProductAccess" class="text-center flex flex-col items-center justify-center">
            <Lock01Icon class="h-20 w-20" />
            <UITextLgMedium>
              {{ productAccessMessage }}
            </UITextLgMedium>
          </div>
        </div>
      </div>
    </div>
    <div class="m-10">
      <LessonsStats :completed="completedPosts" :total="totalPosts" :progress-color="progressColor" class="mb-3" />
    </div>
    <div class="m-10 category-post-list-container bg-white shadow course-background">
      <div class="h-screen overflow-y-scroll course-section overflow-x-clip">
        <div class="py-5 flex justify-center select-none">
          <CategorySlider :category-tree="categoryTree" :category-border="categoryBorder"
            @change-category-index="changeSelectedCategoryIndex" :hasProductAccess="hasProductAccess" />
        </div>
        <div>
          <NewYorkCategoryPostTree :level="0" :category-tree="categoryTree[currentCategoryIndex]"
            :completed-posts="postIdsCompleted" :template-id="templateId" :available="categoryTree[currentCategoryIndex] &&
              categoryTree[currentCategoryIndex].node &&
              categoryTree[currentCategoryIndex].node.available
              " :hasProductAccess="hasProductAccess" />
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, toRaw } from 'vue'
import NewYorkCategoryPostTree from '@/components/product/NewYorkCategoryPostTree.vue'
import LessonsStats from '@/components/product/LessonsStats.vue'
import CategorySlider from '@/components/common/CategorySlider.vue'
import {
  getHeroSpacingClass,
  getThemeSettings,
  getSections,
  getProgressBarColor,
} from '@/helper/index'
import { Lock01Icon } from '@gohighlevel/ghl-icons/24/outline'
import { UITextLgMedium } from '@gohighlevel/ghl-ui'
import { getProductAccessMessage } from '../../../helper/offerAccess'

export default defineComponent({
  components: {
    NewYorkCategoryPostTree,
    LessonsStats,
    CategorySlider,
    Lock01Icon,
    UITextLgMedium,
  },
  props: {
    heroSection: {
      type: Object,
    },
    product: {
      type: Object,
    },
    instructor: {
      type: Object,
    },
    categoryTree: {
      type: Array,
    },
    nextPost: {
      type: Object,
    },
    completedPosts: Number,
    totalPosts: Number,
    postIdsCompleted: Set,
    courseCompleted: Boolean,
    productStarted: Boolean,
    logo: String,
    hasProductAccess: {
      type: Boolean,
    },
  },
  data() {
    return {
      styles: '',
      currentCategoryIndex: 0,
      themeSettings: {},
      sections: {},
      progressColor: '',
    }
  },
  computed: {
    productAccessMessage() {
      return getProductAccessMessage(this.hasProductAccess, this.product)
    },
    brandLogoUrl(): string {
      return this.logo || this.$store.state.logoUrl
    },
    templateId(): string {
      return this.$route.query.template_id
    },
    hero(): any {
      return this.sections?.hero
    },
    heroCustomBackground(): boolean {
      const background = this.hero?.background?.background
      if (background) {
        return true
      }
      return false
    },
    heroButton(): any {
      return this.hero?.button
    },
    buttonContent(): any {
      return this.heroButton?.content
    },
    showLogo(): boolean {
      if (this.themeSettings) {
        return this.themeSettings.logoPlacement === 'hero'
      }
      return false
    },
    categoryBorder(): string {
      let color = '#3b82f6'
      if (this.themeSettings) {
        color = this.themeSettings?.primaryStyle?.color
      }
      if (this.sections?.courseBody?.category?.color) {
        color = this.sections.courseBody.category?.color || '#3b82f6'
      }
      return color
    },
    heroSpacing(): string {
      if (this.sections?.hero?.background?.spacing) {
        return getHeroSpacingClass(this.sections.hero.background.spacing)
      }
      return null
    },
    device(): string {
      return this.$store.getters.device
    },
    newMobileScreens(): string {
      return this.$store.getters.newMobileScreens
    },
    buttonText() {
      if (this.productStarted) {
        return this.totalPosts === this.completedPosts
          ? 'Go to Course'
          : 'Resume Course'
      }
      return this.buttonContent || 'Start Course'
    },
  },
  methods: {
    loadStylesheetTags() {
      const customStyle = document.createElement('link')
      customStyle.setAttribute('rel', 'stylesheet')
      customStyle.setAttribute('vmid', 'newYorkFonts')
      customStyle.setAttribute(
        'href',
        'https://fonts.googleapis.com/css?family=Lato'
      )
      document.head.appendChild(customStyle)
    },
    handleNextPostClick() {
      this.$emit('goToNextPost')
    },
    changeSelectedCategoryIndex(val: number) {
      this.currentCategoryIndex = val
    },
  },
  mounted() {
    this.themeSettings = getThemeSettings(this.product)
    this.sections = getSections(this.product)
    this.progressColor = getProgressBarColor(this.product)
    this.loadStylesheetTags()
  },
})
</script>
<style scoped>
.newyork-template {
  font-family: Lato;
}

#load-next-post:hover {
  opacity: 0.8;
}

#banner {
  min-height: calc((100vw / 4.57));
  z-index: auto;
}
</style>
