import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "block pt-4 grid grid-cols-2 gap-3 lg:grid lg:grid-cols-3 lg:gap-4 mt-20" }
const _hoisted_2 = { class: "px-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_shimmer = _resolveComponent("shimmer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(6, (i) => {
      return _createElementVNode("div", {
        class: "w-full lg:w-full shadow lg:shadow-lg rounded mb-3 md:mb-0",
        key: i
      }, [
        _createVNode(_component_shimmer, { class: "w-full h-24 md:h-40 rounded lg:h-48" }),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_shimmer, { class: "w-2/3 h-2 my-1 rounded-lg" }),
          _createVNode(_component_shimmer, { class: "w-full h-10 rounded-lg" })
        ])
      ])
    }), 64))
  ]))
}