<template>
  <UIModal :show="openModal" :width="400">
    <template #header>
      <UIModalHeader type="success" @close="closeModal" id="modal-header"
        :icon="showPaymentConfirmation ? checkIcon : icon" :title="modalTitleAndDescription.title"
        :description="modalTitleAndDescription.description" />
      <div class="w-full" v-if="!showPaymentConfirmation">
        <div class="v2-offer-title font-family-inter pb-5">
          {{ offer.title }}
        </div>
        <div>
          <div v-if="offer">
            <div>
              <UITextXsMedium class="amount-paid-text text-gray-700 font-family-inter">
                Amount to be paid
              </UITextXsMedium>
              <UIDisplaySmMedium class="price-text text-gray-900 font-family-inter">
                {{
                  `${fetchCurrencySymbol(offer.currency)}${localCouponData ? Number(localCouponData?.total).toFixed(2) :
                    Number(actualOfferPrice).toFixed(2)
                  }`
                }}
              </UIDisplaySmMedium>
            </div>
            <UIButton id="have-coupon-button" :text="true" v-if="couponsFeatureFlag && showCoupon"
              @click="toggleCouponModal">
              <UITextSmMedium :class="localCouponData && localCouponData.isValidCode
                ? 'text-red-600'
                : ''
                ">
                {{
                  !localCouponData || !localCouponData.isValidCode
                    ? 'Have a coupon? Click here'
                    : 'Remove applied coupon'
                }}
              </UITextSmMedium>
            </UIButton>
            <CouponFormModal v-if="couponsFeatureFlag && showCoupon" :isCouponModalActive="isCouponModalActive"
              :offer="offer" :appliedCouponData="appliedCouponData" :localCouponData="localCouponData"
              :currency="fetchCurrencySymbol(offer.currency)" @toggleCouponModal="toggleCouponModal"
              @updateCouponData="updateCouponData" />
            <div class="mt-3" v-if="localCouponData && localCouponData.isValidCode">
              <div class="flex items-center justify-between">
                <UITextSmRegular>Discount applied (coupon)</UITextSmRegular>
                <UITextSmMedium>
                  {{
                    `- ${fetchCurrencySymbol(offer.currency)}${Number(localCouponData.discount).toFixed(2)
                    }`
                  }}
                </UITextSmMedium>
              </div>
              <div
                class="grid w-fit grid-cols-2 coupon-tag-layout items-center border border-green-500 text-green-500 py-1 px-2 mt-2 bg-green-50"
                :class="localCouponData.code?.length > 25
                  ? 'rounded-lg'
                  : 'rounded-full'
                  ">
                <Ticket02Icon class="w-4 h-4 mr-1" />
                <UITextSmRegular class="break-all uppercase text-xs font-semibold">
                  {{ localCouponData.code }}
                </UITextSmRegular>
              </div>
            </div>
          </div>
          <div class="font-medium" v-else>Enter Card Details</div>
        </div>
        <div class="flex flex-row space-x-4 py-2" v-if="
          paymentMethods && paymentMethods.length && offerVersion === 'v1'
        ">
          <div class="flex justify-center items-center">
            <input class="cursor-pointer" type="radio" value="savedCards" v-model="chargeCardType"
              @input="errMsg = ''" />
            <div class="text-sm px-2">Saved cards</div>
          </div>
          <div class="flex justify-center items-center">
            <input class="cursor-pointer" type="radio" value="newCard" v-model="chargeCardType" @input="errMsg = ''"
              size="default" />
            <div class="text-sm px-2">New card</div>
          </div>
        </div>
      </div>
    </template>
    <div v-if="!showPaymentConfirmation"
      class="inline-block align-botto rounded-lg text-left overflow-hidden shadow pt-2 transform transition-all mt-8 lg:align-middle md:align-middle w-full offer-modal"
      :class="showPaymentConfirmation
        ? 'dashboard-modal'
        : paymentMethods && paymentMethods.length > 2
          ? 'payment-modal h-auto lg:h-[32rem]'
          : 'offer-modal'
        ">
      <div class="flex flex-col items-start">
        <div class="w-full text-left" :class="chargeCardType === 'savedCards' && paymentMethods.length > 3
          ? 'h-48 overflow-y-scroll'
          : ''
          ">
          <div class="w-full">
            <div v-if="showPaymentConfirmation"></div>
            <div v-if="chargeCardType === 'savedCards' && offerVersion === 'v1'">
              <template :key="item.id" v-for="item in paymentMethods">
                <div
                  class="rounded-md px-3 py-2 my-2 flex items-center justify-start space-x-4 cursor-pointer border hover:border-blue-300"
                  :class="selectedSavedCard === item.id &&
                    'border-2 border-blue-500 bg-gray-200'
                    " @click="selectSavedCard(item.id)">
                  <div class="w-full flex items-center justify-between px-2">
                    <div class="flex items-center">
                      <input class="cursor-pointer" type="radio" :value="item.id" v-model="selectedSavedCard"
                        size="default" />
                      <img :src="item.card &&
                        item.card.brand &&
                        getCardIcon(item.card.brand)
                        " class="w-12 pl-2" alt="Card" />
                    </div>
                    <div class="flex flex-col w-2/3 justify-end items-center text-sm text-gray-700 font-normal">
                      <div class="w-full flex justify-end">
                        <span>**** **** ****</span> {{ item.card.last4 }}
                      </div>
                      <span class="w-full flex justify-end">{{
                        item.card &&
                        `${item.card.brand
                          .substring(0, 1)
                          .toUpperCase()}${item.card.brand.substring(
                            1,
                            item.card.brand.length
                          )}`
                      }}</span>
                    </div>
                  </div>
                </div>
              </template>
            </div>
            <div v-else>
              <stripe-card-element v-if="offerVersion === 'v1'" :location="location" :stripeCardError="errMsg"
                :offer="offer" :isUpsell="isUpsell" @stripe-card-error="(value) => (errMsg = value)"
                @stripe-elements="(value) => (stripeElements = value)" @set-stripe="(value) => (stripe = value)" />
              <PaymentV2 v-if="offerVersion === 'v2'" :key="updateKey" :offer="offer" :locationId="locationId"
                :contactId="contact.id" :sourceId="offer.id" :priceIds="offer.paymentPriceId"
                :couponData="localCouponData" :size="'sm'" :purchaseV2="purchaseV2" :closeModal="closeModal"
                :isLivePayment="isLivePayment" ref="childRef" />
            </div>
          </div>
        </div>
        <div class="text-left text-red-500 text-sm px-2 pb-2" v-if="errMsg">
          {{ errMsg }}
        </div>
      </div>
    </div>
    <div v-if="!showPaymentConfirmation" class="text-center secure-text font-family-inter text-gray-400 pt-1 pb-5">
      *100% secure & safe payments*
    </div>

    <template #footer>
      <div v-if="(!showPaymentConfirmation || !isMobileApp)" class="flex"
        :class="showPaymentConfirmation ? 'pt-5' : ''">
        <UIButton type="default" id="modal-ok" class="w-1/2 mr-1" @click="closeModal">
          Cancel
        </UIButton>
        <UIButton :loading="loadingPayButton" type="primary" id="modal-ok" :disabled="loadingPayButton ||
          disableCheckoutBtn ||
          (offerVersion === 'v1' &&
            chargeCardType === 'savedCards' &&
            !selectedSavedCard)
          " class="w-1/2" @click="onConfirmPayment">
          {{ !showPaymentConfirmation ? 'Confirm Payment' : dashboardBtn }}
        </UIButton>
      </div>
    </template>
  </UIModal>
</template>
<script lang="ts">
import { defineComponent, toRaw, ref } from 'vue'
import CheckoutService from '@/services/CheckoutService'
import StripeCardElement from './StripeCardElement.vue'
import { currency } from '@/helper/constants'
import { UpsellOfferType } from '@/models/UpsellOffer'
import { isClientPortal, isAppInstalled } from '@/helper'
import PaymentV2 from './PaymentV2.vue'
import {
  isPwa,
} from "@gohighlevel/clientportal-core";
import { v4 as uuidv4 } from 'uuid'
import { OfferType } from '@/models/Offer'
import {
  UIModal,
  UIModalHeader,
  UIButton,
  UITextSmRegular,
  UITextSmMedium,
  UIDisplaySmMedium,
  UITextXsMedium,
} from '@gohighlevel/ghl-ui'
import {
  CreditCard01Icon,
  CheckCircleIcon,
  Ticket02Icon,
} from '@gohighlevel/ghl-icons/24/outline'
import CouponFormModal from '@/components/checkout/CouponFormModal.vue'
import { verifyStripeOrderPayment } from '@/helper/payment.helper'
import { getLocalStorageItem, setLocalStorageItem, utf8ToBase64 } from '@/helper/storage.helper'
const childRef = ref(null)

export default defineComponent({
  name: 'PaymentModal',
  components: {
    StripeCardElement,
    PaymentV2,
    UIModal,
    UIModalHeader,
    UIButton,
    UITextSmRegular,
    UITextSmMedium,
    Ticket02Icon,
    UIDisplaySmMedium,
    UITextXsMedium,
    CouponFormModal,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    location: Object,
    contact: Object,
    paymentProps: Object,
    locationId: String,
    offerId: String,
    checkoutSource: String,
    showThankYouMsg: Boolean,
    freeProduct: Boolean,
    offer: Object,
    isUpsell: {
      type: Boolean,
      default: false,
    },
    upsellType: {
      type: String,
      default: null,
    },
    paymentMethods: {
      type: Array,
      default: null,
    },
    icon: {
      type: Function,
      default: CreditCard01Icon,
    },
    checkIcon: {
      type: Function,
      default: CheckCircleIcon,
    },
    showCoupon: {
      type: Boolean,
      default: false,
    },
    couponData: {
      type: Object,
      default: null,
    },
  },
  data() {
    currency
    return {
      currency,
      stripeAPIToken: '',
      stripe: {} as any,
      stripeElements: {} as any,
      elements: {} as any,
      card: {} as any,
      errMsg: '',
      disableCheckoutBtn: false,
      openModal: false,
      chargeCardType: 'newCard',
      selectedSavedCard: '',
      loadingPayButton: false,
      localCouponData: null,
      isCouponModalActive: false,
      appliedCouponData: null,
      updateKey: uuidv4(),
      errorPurchaseExists: false,
    }
  },
  computed: {
    showPaymentConfirmation() {
      return this.showThankYouMsg || this.errorPurchaseExists
    },
    couponsFeatureFlag() {
      return this.$store.state.featureFlag?.coupons
    },
    isInAppUpsell(): boolean {
      return this.upsellType ? this.upsellType === UpsellOfferType.INAPP : false
    },
    offerVersion(): string {
      return this.offer && this.offer.paymentPriceId ? 'v2' : 'v1'
    },
    isLivePayment(): boolean {
      return toRaw(this.offer).isLivePaymentMode || false
    },
    dashboardBtn(): string {
      return isClientPortal() ? 'Login' : 'View Dashboard'
    },
    modalTitleAndDescription(): { title: string; description: string } {
      const thankYouMessageTitle: string = this.freeProduct
        ? 'Thank you for signing up!'
        : 'Thank you for your purchase!'
      return {
        title: this.showPaymentConfirmation ? thankYouMessageTitle : '',
        description: this.showThankYouMsg || this.errorPurchaseExists
          ? 'We have sent you an email with login credentials'
          : '',
      }
    },
    actualOfferPrice() {
      if (this.offer.type === OfferType.subscription) {
        if (!this.offer.trialDays) {
          return (
            Number(this.offer.amount) + Number(this.offer.setupFee || 0)
          )
        } else {
          return (
            this.offer.setupFee || 0
          )
        }
      }
      return Number(this.offer.amount)
    },
  },
  async mounted() {
    try {
      if (this.$route.query.payment_intent) {
        const cookieName = `p_inp_${this.$route?.query?.sourceId}`
        const cookieValue = JSON.parse(getLocalStorageItem(cookieName))
        await verifyStripeOrderPayment(
          cookieValue.sourceParams,
          this.$route?.query?.altId,
          'location',
          this.$route?.query?.payment_intent,
          this.$route?.query?.setup_intent
        )
        await this.purchaseV2({
          orderId: this.$route.query.sourceId,
        })
        //this.$emit('order-successful', {})
      }

      this.stripeAPIToken = this?.location?.stripe
      this.openModal = this.showModal
      if (this.paymentMethods?.length) {
        this.chargeCardType = 'savedCards'
      }
      this.localCouponData = this.couponData
    } catch (error) {
      console.error('Error mounting PaymentModal', error)
    }
  },
  methods: {
    setPaymentCookies() {

      const cookieData = JSON.stringify({
        showModal: this.showModal,
        location: this.location,
        contact: this.contact,
        locationId: this.locationId,
        offerId: this.offerId,
        checkoutSource: this.checkoutSource,
        showThankYouMsg: this.showThankYouMsg,
        freeProduct: this.freeProduct,
        offer: this.offer,
        isUpsell: this.isUpsell,
        upsellType: this.upsellType,
        paymentMethods: this.paymentMethods,
        icon: this.icon,
        checkIcon: this.checkIcon,
        showCoupon: this.showCoupon,
        couponData: toRaw(this.couponData),
      })
      setLocalStorageItem(`p_inp`, utf8ToBase64(cookieData), 5)
    },
    fetchCurrencySymbol(currency: string) {
      if (currency) {
        return this.currency[currency].symbol
      }
      return ''
    },
    toggleCouponModal() {
      this.isCouponModalActive = !this.isCouponModalActive
      if (this.localCouponData && !this.localCouponData.isValidCode) {
        this.appliedCouponData = null
      }
      this.updateKey = uuidv4()
    },
    updateCouponData(data: any) {
      this.localCouponData = data
      this.appliedCouponData = data
    },
    async onConfirmPayment() {
      this.loadingPayButton = true
      if (this.showThankYouMsg) {
        this.$emit('go-to-preview')
      } else {
        if (this.offerVersion === 'v1') {
          await this.purchase()
        } else {
          await this.inititeOrder()
        }
      }
      this.loadingPayButton = false
    },
    async inititeOrder() {
      await this.$refs.childRef.processPayment()
    },
    getCardIcon(type: string) {
      switch (type) {
        case 'amex':
          return require('@/assets/cards/amex.svg')
        case 'diners':
          return require('@/assets/cards/diners.svg')
        case 'discover':
          return require('@/assets/cards/discover.svg')
        case 'mastercard':
          return require('@/assets/cards/mastercard.svg')
        case 'unionpay':
          return require('@/assets/cards/unionpay.svg')
        case 'visa':
          return require('@/assets/cards/visa.svg')
        default:
          return require('@/assets/cards/card.svg')
      }
    },
    async purchase() {
      try {
        this.disableCheckoutBtn = true

        const { data: setupIntentResponse } = await CheckoutService.setupIntent(
          {
            locationId: this.locationId,
            offerId: this.offerId,
          }
        )

        const {
          client_secret: clientSecret,
          payment_method: setupIntentPaymentMethod,
        } = setupIntentResponse

        if (!clientSecret) {
          throw new Error(
            'Client secret missing! Payment setup failed, try again!'
          )
        }

        let paymentMethod
        let isNewCard = true
        let stripeCustomerId

        if (this.chargeCardType === 'savedCards') {
          paymentMethod = this.selectedSavedCard
          isNewCard = false
          stripeCustomerId = this.contact.stripeCustomerId
        } else {
          if (setupIntentPaymentMethod) {
            paymentMethod = setupIntentPaymentMethod
          }

          const cardNumber = this.stripeElements.getElement('cardNumber')

          const result = await this.stripe.confirmCardSetup(clientSecret, {
            payment_method: {
              type: 'card',
              card: cardNumber,
              billing_details: {
                name:
                  this.contact.full_name_lower_case || this.contact.first_name,
              },
            },
          })

          if (result.error) {
            throw new Error(result.error.message)
          }

          if (result.setupIntent) {
            paymentMethod = result.setupIntent.payment_method
          }
        }

        const payload = {
          fingerprint: this.contact.fingerprint,
          contactId: this.contact.id,
          locationId: this.offer.locationId,
          productType: 'offer',
          paymentMethodId: paymentMethod,
          clientSecret,
          checkoutSource: this.checkoutSource,
          checkoutType: 'purchase',
          isNewCard: isNewCard,
          stripeCustomerId: stripeCustomerId,
        }

        try {
          const {
            data: { ct, previewUrl, upsellOfferId, featureFlag },
          } = await CheckoutService.checkout(this.offerId, payload)
          this.$emit('order-successful', {
            ct,
            previewUrl,
            upsellOfferId,
            featureFlag,
            closeModal: this.isInAppUpsell ? true : false,
          })
          this.disableCheckoutBtn = false
          // if (this.isInAppUpsell) {
          //   setTimeout(() => {
          //     window.close()
          //   }, 5000)
          // }
        } catch (err) {
          throw new Error(err?.response?.data?.msg)
        }
      } catch (error) {
        this.errMsg = error
        this.errorPurchaseExists = true
        this.disableCheckoutBtn = false
      }
    },

    async purchaseV2(eventData) {
      try {
        this.disableCheckoutBtn = true
        const payload = {
          fingerprint: this.contact?.fingerprint,
          locationId: this.locationId,
          productType: 'offer',
          checkoutSource: this.checkoutSource,
          checkoutType: 'purchase',
          chargeId: eventData.orderId
            ? eventData.orderId
            : eventData.verifiedTrackingId,
          contactId: this.contact.id,
          offerId: this.offerId,
          offerVersion: 'v2',
          appliedCoupon: this.localCouponData
            ? this.localCouponData.code
            : null,
        }

        try {
          const {
            data: { ct, previewUrl, upsellOfferId, featureFlag },
          } = await CheckoutService.checkout(this.offerId, payload)
          this.$emit('order-successful', {
            ct,
            previewUrl,
            upsellOfferId,
            featureFlag,
            closeModal: this.isInAppUpsell ? true : false,
          })
          this.disableCheckoutBtn = false
        } catch (err) {
          throw new Error(err?.response?.data?.msg)
        }
      } catch (error) {
        this.errMsg = error
        this.errorPurchaseExists = true
        this.disableCheckoutBtn = false
      }
    },
    closeModal() {
      this.openModal = false
      this.$emit('close')
    },
    selectSavedCard(payload: string) {
      this.errMsg = ''
      this.selectedSavedCard = payload
    },
    isClientPortal() {
      return isClientPortal()
    },
    isMobileApp() {
      return isClientPortal() && isPwa()
    }
  },
  watch: {
    showModal(val: boolean) {
      this.openModal = val
      if (val) {
        this.setPaymentCookies()
      }
    },
    couponData(newData: any) {
      this.localCouponData = newData
    },
  },
})
</script>
<style scoped>
.dashboard-modal {
  height: 20rem;
}

.amount-style {
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
}

.action-left {
  width: 50% !important;
}

.action-right {
  width: 50% !important;
}

.v2-offer-title {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.amount-paid-text {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.price-text {
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 60px;
  /* 125% */
  letter-spacing: -0.96px;
}

.secure-text {
  /* Text xs/Regular */
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 150% */
}

.font-family-inter {
  font-family: 'Inter';
}
</style>
