<script lang="ts" setup>
import { sanitizeJavaScriptCode } from "@/helper";
import { INSTRUCTOR_DEFAULT_VALUES, MAX_DESCRIPTION_CHARACTERS, userAvatarImage } from "@/helper/constants";
import { replaceBucketUrlWithCdnUrl } from "@/helper/filter";
import Product from "@/models/Product";
import { UIAvatar } from "@gohighlevel/ghl-ui";
import { computed, ref } from "vue";

const props = defineProps<{
  product: Product;
}>();


const isExpanded = ref(false);

const needsViewMore = computed(() => {
  return props.product?.description && sanitizeJavaScriptCode(props.product?.description).length > MAX_DESCRIPTION_CHARACTERS;
});

const getDescriptionClass = computed(() => {
  return {
    "!max-h-20 line-clamp-3 overflow-y-hidden": !isExpanded.value && needsViewMore.value,
  };
});

const toggleView = () => {
  isExpanded.value = !isExpanded.value;
};
</script>
<template>
  <div>
    <div class="w-11/12 flex flex-col items-start mx-auto gap-2 my-4" v-if="product.description">
      <span class="font-semibold text-[#101828] text-base">Course description</span>
      <p class="inline m-0">
        <span class="text-[#667085] text-xs font-normal post-description mobile-post-details"
          :class="getDescriptionClass" v-html="product.description"></span>
        <span v-if="needsViewMore && !isExpanded" @click="toggleView"
          class="text-blue-500 font-semibold text-sm cursor-pointer">
          View More
        </span>
        <span v-if="needsViewMore && isExpanded" @click="toggleView"
          class="text-blue-500 font-semibold text-sm cursor-pointer">
          View Less
        </span>
      </p>
    </div>
    <hr v-if="product.description" />

    <div class="w-11/12 flex flex-col items-start mx-auto gap-2 my-4 ">
      <span class="font-semibold text-[#101828] text-base  break-all line-clamp-1">{{
        product.customizations?.instructorHeading || INSTRUCTOR_DEFAULT_VALUES.title
      }}</span>
      <div class="flex items-center gap-4 my-2 w-full">
        <UIAvatar :size="'large'" :objectFit="'fill'" :round="true" class="min-h-10 min-w-10"
          :src="replaceBucketUrlWithCdnUrl(product.customizations?.instructorHeadshot) || INSTRUCTOR_DEFAULT_VALUES.headshot" />
        <div class="flex flex-col items-start gap-2 ">
          <span class="font-semibold text-sm text-[#101828] break-all line-clamp-1">{{
            product.customizations?.instructorName || INSTRUCTOR_DEFAULT_VALUES.name }}
          </span>
          <span class="text-[#667085] text-xs  break-all line-clamp-1">
            {{
              (product.customizations?.instructorTitle) ||
              INSTRUCTOR_DEFAULT_VALUES.heading }}
          </span>
        </div>
      </div>
      <span class="text-[#667085] w-full break-all">{{
        product.customizations?.instructorBio || INSTRUCTOR_DEFAULT_VALUES.bio
      }}</span>
    </div>
  </div>
</template>

<style scoped>
.post-description {
  max-height: 10000px;

  height: auto !important;

}

.post-description li {
  white-space: normal !important;
}
</style>
