<script setup lang="ts">
import CategoryOverviewV2 from "@/components/mobile/CategoryOverviewV2.vue";
import CategorySyllabusV2 from "@/components/mobile/CategorySyllabusV2.vue";
import CategoryShimmerV2 from "@/components/shimmer/mobile/CategoryShimmerV2.vue";
import {
  computeNextPost,
  createCategoryPostTree,
  getMobileTheme,
  isClientPortal
} from "@/helper";
import { COURSE_DETAILS_V2_TABS, defaultCourseThumbnail } from "@/helper/constants";
import { replaceBucketUrlWithCdnUrl } from "@/helper/filter";
import { getProductAccessMessage } from "@/helper/offerAccess";
import CategoryTreeNode from "@/models/CategoryTreeNode";
import Product from "@/models/Product";
import { CategoryService, PostService, UserPurchaseService } from "@/services";
import {
  UIProgress,
  UITextMdMedium,
  UITextSmMedium,
  UITextXsMedium
} from "@gohighlevel/ghl-ui";
import { cloneDeep } from "lodash";
import { computed, onMounted, ref, toRaw, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import CategoryList from "../CategoryList.vue";
import { PostContentType } from "@/models/Post";
import TabComponentV2 from "@/components/mobile/TabComponentV2.vue";

const props = defineProps<{
  product: Product;
  instructor: Record<string, any>;
  allCompletedPostsData?: Record<string, any> | null;
}>();

const NODE_TYPES = {
  CATEGORY: "Category",
  POST: "Post",
  QUIZ: "Quiz",
  LESSON: "Lesson",
};

const route = useRoute();
const router = useRouter();
const store = useStore();

const selectedTab = ref('overview');
const categories = ref<any[]>([]);
const totalPosts = ref(0);
const completedPosts = ref(0);
const categoryIdsCompleted = ref(new Set<string>());
const categoryProgress = ref<Record<string, number>>({});
const fetching = ref(false);
const allCategories = ref<any[]>([]);
const lessonSequenceMap = ref(new Map<string, number>());
const categoryProgressCount = ref(
  new Map<string, { totalPosts: number; completedPosts: number }>()
);
const nextPostData = ref<Record<string, any>>({});
const source = ref(route.query.source as string);
const categoryTree = ref<Array<CategoryTreeNode>>([]);

const newMobileScreens = computed(() => store.getters.newMobileScreens);
const hasProductAccess = computed(() => toRaw(props.product)?._data?.hasProductAccess);
const productId = computed(() => route.params.id || props.product.id);
const device = computed(() => store.getters.device);
const postIdsCompleted = computed(() =>
  props.allCompletedPostsData?.completedPostIds
    ? props.allCompletedPostsData?.completedPostIds
    : new Set()
);
const productAccessMessage = computed(() =>
  getProductAccessMessage(hasProductAccess.value, props.product)
);

async function fetchCategories() {
  const allCategoriesResponse = await UserPurchaseService.getCategories({
    product_id: productId.value,
  });
  categories.value = allCategoriesResponse
    .filter((c: any) => c.parentCategory === null)
    .sort((a, b) => a.sequenceNo - b.sequenceNo);

  allCategories.value = allCategoriesResponse;
}

function fetchCompletedPosts() {
  categoryIdsCompleted.value = new Set(
    categories.value.reduce((acc, categoryElem) => {
      const { posts } = categoryElem;
      const categoryPostIds = posts.map((e) => e.id);
      const completedPostLengthInCategory = categoryPostIds.filter((element) =>
        postIdsCompleted.value?.has(element)
      ).length;
      if (
        categoryPostIds.length > 0 &&
        completedPostLengthInCategory === categoryPostIds.length
      ) {
        acc.push(categoryElem.id);
      }
      return acc;
    }, [] as string[])
  );

  completedPosts.value = postIdsCompleted.value?.size;
}

async function fetchNoOfPublishedPosts() {
  const publishedResp = await PostService.noOfPublishedPosts({
    product_id: productId.value,
  });
  totalPosts.value = publishedResp.count;
}

async function fetchCategoryProgress() {
  const categoriesIds = categories.value.map((c: any) => c.id);
  const resp = await CategoryService.fetchCategoriesProgress(
    productId.value,
    categoriesIds
  );
  categoryProgress.value = resp.reduce((agg, data) => {
    const { categoryId, progress } = data;
    const newProgress = {};
    newProgress[categoryId] = progress;
    return { ...agg, ...newProgress };
  }, {});
}

async function fetchCompletionData() {
  fetchCompletedPosts();
  await fetchNoOfPublishedPosts();
}

function getTotalCompletedPosts(c: any) {
  let count = 0;
  c.posts.forEach((p: any) => {
    if (postIdsCompleted.value?.has(p.id)) {
      count += 1;
    }
  });
  return count;
}

function updateCategoryProgressCount() {
  allCategories.value.forEach((c: any) => {
    if (c.parentCategory === null) {
      categoryProgressCount.value.set(c.id, {
        ...categoryProgressCount.value.get(c.id),
        totalPosts: c.posts.length,
        completedPosts: getTotalCompletedPosts(c),
      });
    } else {
      const categoryProgress = categoryProgressCount.value.get(c.parentCategory);
      const prevTotalPosts =
        categoryProgress && categoryProgress.totalPosts ? categoryProgress.totalPosts : 0;
      const prevCompletedPosts =
        categoryProgress && categoryProgress.completedPosts
          ? categoryProgress.completedPosts
          : 0;
      categoryProgressCount.value.set(c.parentCategory, {
        ...categoryProgress,
        totalPosts: c.posts.length + prevTotalPosts,
        completedPosts: getTotalCompletedPosts(c) + prevCompletedPosts,
      });
    }
  });
}


function handleNextPostClick() {
  if (!hasProductAccess.value || !totalPosts.value || !categoryTree.value.length) return;

  if (nextPostData.value.id || hasProductAccess.value) {
    const node = nextPostData.value?.node;
    if (node?.contentType === PostContentType.assignment) {
      router.push({
        name: "mobile-assignment",
        params: {
          id: productId.value,
          category_id: nextPostData.value.parent,
          post_id: nextPostData.value.id,
        },
        query: {},
      });
    } else if (node?.contentType === PostContentType.quiz) {
      router.push({
        name: "mobile-quiz-overview",
        params: {
          id: productId.value,
          category_id: nextPostData.value.parent,
          post_id: nextPostData.value.id,
        }
      });
    } else {
      router.push({
        name: "post-overview",
        params: {
          id: productId.value,
          category_id: nextPostData.value.parent,
          post_id: nextPostData.value.id,
        }
      });
    }
  }
}
/*
 @description: This function is used to get the lesson sequence map.
 @param {CategoryTreeNode[]} treeNodes - The tree nodes.
 @returns {Map<string, number>} - The lesson sequence map.
*/
function getLessonSequenceMap(treeNodes: CategoryTreeNode[]): Map<string, number> {
  const lessonSequenceMap = new Map<string, number>();
  let currentSequenceNo = 1;

  function traverseTree(node: CategoryTreeNode) {
    if (node.type === NODE_TYPES.POST) {
      lessonSequenceMap.set(node.id, currentSequenceNo);
      currentSequenceNo++;
    }

    // Traverse the children of the current node (if any)
    for (const child of node?.childs || []) {
      traverseTree(child);
    }
  }
  // Start the traversal from each root node in the array
  for (const treeNode of treeNodes) {
    traverseTree(treeNode);
  }

  return lessonSequenceMap;
}



onMounted(async () => {
  fetching.value = true;
  await fetchCompletionData();
  await fetchCategories();
  await fetchCategoryProgress();
  fetching.value = false;
  updateCategoryProgressCount();

  const categoryPostTree = createCategoryPostTree(null, allCategories.value);
  categoryTree.value = categoryPostTree;
  lessonSequenceMap.value = getLessonSequenceMap(categoryPostTree);


  nextPostData.value = computeNextPost(
    cloneDeep(categoryPostTree),
    postIdsCompleted.value
  );
});

function handleTabChange(tab: string) {
  selectedTab.value = tab;
}

onMounted(() => {
  const selected_tab = route.query?.selected_tab;
  if (selected_tab) {
    selectedTab.value = Array.isArray(selected_tab) ? selected_tab[0] : selected_tab
  }
})

</script>

<template>
  <div class="w-full h-full">
    <div v-if="device === 'mobile'">
      <CategoryShimmerV2 v-if="fetching" />
      <div v-else class="category-list">
        <img :src="replaceBucketUrlWithCdnUrl(product?.posterImage) || defaultCourseThumbnail
          " class="w-full h-1/3 border-b border-gray-200"
          :class="replaceBucketUrlWithCdnUrl(product?.posterImage) ? 'object-fill' : 'object-cover'" />
        <div class="w-11/12 flex items-center justify-start gap-2 mx-auto my-2">
          <UITextXsMedium class="text-[--accent-color] text-xs font-bold">{{ totalPosts }} {{ totalPosts > 1 ? 'Lessons'
            : 'Lesson' }}</UITextXsMedium>
          <div class="bg-[--accent-color] text-xs font-bold rounded w-1 h-1"></div>
          <UITextXsMedium class="text-[--accent-color] text-xs font-bold">{{ categories.length }} {{ categories?.length
            > 1 ? 'Modules' : 'Module' }}
          </UITextXsMedium>
        </div>
        <UITextMdMedium class="w-11/12 mx-auto font-semibold text-[#101828]">{{
          product.title
          }}</UITextMdMedium>
        <div class="w-11/12 mx-auto flex items-center gap-3 mt-2">
          <UIProgress :percentage="(completedPosts / totalPosts) * 100" :type="'line'" :indicatorPlacement="'outside'"
            :status="undefined" :showIndicator="false" :color="getMobileTheme().primaryHexCode" :strokeWidth="8"
            :indicatorTextColor="'#000000'" />
          <UITextSmMedium class="font-semibold text-[#101828]">{{ completedPosts || 0 }}/{{ totalPosts || 0 }}
          </UITextSmMedium>
        </div>
        <div class="w-11/12 mx-auto my-4">
          <div
            class="w-full rounded-lg flex items-center justify-center py-0 px-4 text-[13px] font-semibold text-white h-9 cursor-pointer"
            :class="{ 'clickable': (hasProductAccess || (totalPosts && categoryTree.length)) }" :style="{
              backgroundColor: !hasProductAccess || !totalPosts || !categoryTree.length
                ? 'rgb(152 162 179)'
                : `${getMobileTheme().primaryHexCode} !important`,
            }" @click="handleNextPostClick">
            <span v-if="!hasProductAccess">{{ productAccessMessage }}</span>
            <span v-else>{{
              completedPosts > 0 ? "Resume Learning" : "Start Learning"
              }}</span>
          </div>
        </div>
        <div class="w-11/12 flex items-center justify-evenly mx-auto gap-4">
          <TabComponentV2 :selectedTab="selectedTab" :tabOptions="COURSE_DETAILS_V2_TABS"
            @tab-change="handleTabChange" />

        </div>
        <Transition name="fade">
          <!-- SYLLABUS -->
          <CategorySyllabusV2 v-if="selectedTab === 'syllabus'" :categoryTree="categoryTree"
            :allCompletedPostsData="allCompletedPostsData" :totalPosts="totalPosts" :productId="productId"
            :hasProductAccess="hasProductAccess" />

          <!-- OVERVIEW -->
          <CategoryOverviewV2 v-else :product="product" />
        </Transition>
      </div>
    </div>
    <div v-else>
      <CategoryList :product="product" :instructor="instructor" :allCompletedPostsData="allCompletedPostsData" />
    </div>
  </div>
</template>
<style scoped>
.accordion-item {
  transition: max-height 0.3s ease;
}

.category-list {
  width: 100%;
  height: calc(100dvh - 4rem);
  overflow-y: scroll;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease-out;
}

.fade-enter-from,
.fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}
</style>
