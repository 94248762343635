import VuexPersistence from 'vuex-persist'
import { createStore } from 'vuex'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
})

export default createStore({
  state: {
    logoUrl: '',
    faviconUrl: '',
    pageTitle: '',
    userAvatar: '',
    cToken: '',
    contact: {},
    previewUrl: '',
    mobileTheme: {
      primary: 'mobile-default-primary',
      secondary: 'mobile-default-secondary',
    },
    featureFlag: {},
    activeLibrary: 'All Courses',
    device: 'desktop',
    newMobileScreens: true,
    pwaAppSettings: {},
    pwaTriggerInstallAlert: false,
    // siteCustomCss: '',
    // siteCustomJs: ''
    appBaseMeta: {},
    clientPortalUserData: null,
    clientPortalDomainData: null,
    locationId: '',
    appFlags: {
      isLegacyWeb: false,
      isLegacyMobileBrowser: false,
      isLegacyPWA: false,
      isClientPortalWeb: false,
      isClientPortalMobileBrowser: false,
      isClientPortalMobileIOS: false,
      isClientPortalMobileAndroid: false,
      isCpAppInstalled: false,
    },
    openCurriculumComments: false,
    openSearchDrawer: false,
  },
  getters: {
    logo(state: any): string {
      return state.logoUrl
    },
    favicon(state: any): string {
      return state.faviconUrl
    },
    pageTitle(state: any): string {
      return state.pageTitle || 'Memberships'
    },
    userAvatar(state: any): string {
      return state.userAvatar
    },
    activeLibrary(state: any): string {
      return state.activeLibrary
    },
    device(state: any): string {
      return state.device
    },
    mobileTheme(state: any): any {
      return state.mobileTheme
    },
    newMobileScreens(state: any): any {
      return state.newMobileScreens
    },
    pwaAppSettings(state: any): any {
      return state.pwaAppSettings
    },
    pwaTriggerInstallAlert(state: any): any {
      return state.pwaTriggerInstallAlert
    },
    appBaseMeta(state: any): any {
      return state.appBaseMeta
    },
    clientPortalUserData(state: any) {
      return state.clientPortalUserData
    },
    clientPortalDomainData(state: any) {
      return state.clientPortalDomainData
    },
    communitiesSource(state: any) {
      return state.source
    },
    locationId(state: any): string {
      return state.locationId
    },
    // },
    // siteCustomCss(state: any): string {
    //   return state.siteCustomCss
    // }
    appFlags(state: any) {
      return state.appFlags
    },
    openCurriculumComments(state: any): string {
      return state.openCurriculumComments
    },
    openSearchDrawer(state: any): string {
      return state.openSearchDrawer
    },
  },
  mutations: {
    APP_BASE_META(state: any, payload) {
      state.appBaseMeta = payload
    },
    SAVE_BRAND_DATA(state: any, payload) {
      state.logoUrl = payload.logoUrl
      state.faviconUrl = payload.faviconUrl
      state.pageTitle = payload.pageTitle || payload.title
      // state.siteCustomCss = payload.customCss || ''
      // state.siteCustomJs = payload.customJs || ''
    },
    SAVE_AVATAR(state: any, payload: string) {
      state.userAvatar = payload
    },
    SAVE_CHECKOUT_DATA(state: any, payload) {
      state.contact = payload
    },
    SAVE_FEATURE_FLAG(state: any, payload) {
      state.featureFlag = payload
    },
    SAVE_DASHBOARD_DATA(state: any, payload) {
      state.cToken = payload.cToken
      state.previewUrl = payload.previewUrl
      state.featureFlag = payload.featureFlag
    },
    UPDATE_ACTIVE_LIBRARY(state: any, payload) {
      state.activeLibrary = payload
    },
    SET_DEVICE(state: any, payload) {
      state.device = payload
    },
    UPDATE_MOBILE_THEME(state: any, payload) {
      state.mobileTheme = {
        ...state.mobileTheme,
        ...payload,
      }
    },
    SHOW_NEW_MOBILE_SCREENS(state: any, payload) {
      state.newMobileScreens = payload
    },
    SET_PWA_APP_SETTINGS(state: any, payload) {
      if (payload.addWithExisting) {
        state.pwaAppSettings = {
          ...state.pwaAppSettings,
          ...payload,
        }
        return
      }
      state.pwaAppSettings = payload
    },
    SET_TRIGGER_PWA_INSTALL(state: any, payload) {
      state.pwaTriggerInstallAlert = payload
    },
    SET_CLIENT_PORTAL_USER_DATA(state: any, payload) {
      state.clientPortalUserData = payload
    },
    SET_CLIENT_PORTAL_DOMAIN_DATA(state: any, payload) {
      state.clientPortalDomainData = payload
    },
    SET_COMMUNITIES_FLAG(state: any, payload) {
      state.source = payload
    },
    SAVE_LOCATION_ID(state: any, payload) {
      state.locationId = payload
    },
    SET_APP_FLAGS(state: any, payload) {
      state.appFlags = {
        ...state.appFlags,
        ...payload,
      }
    },
    SET_CURRICULUM_COMMENTS_OPEN(state: any, payload) {
      state.openCurriculumComments = payload
    },
    SET_SEARCH_DRAWER_OPEN(state: any, payload) {
      state.openSearchDrawer = payload
    },
  },
  actions: {},
  modules: {},
  plugins: [vuexLocal.plugin],
})
