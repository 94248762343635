<template>
  <div
    id="post-details-container"
    :class="
      disableActions && disableActions.pointerEvents === 'none' ? 'px-2' : ''
    "
  >
    <CategoryPostBreadcrumbs
      :product-id="productId"
      :product-title="product && product.title"
      :category-id="categoryId"
      :category-title="category && category.title"
      :post-id="postId"
      :post-title="activePost && activePost.title"
    />
    <div class="post-details md:grid grid-cols-12 gap-4 mt-4">
      <div class="video-post-body-container col-span-8">
        <div class="w-full">
          <video-player
            id="post-video"
            ref="videoContainer"
            v-if="activePost.video && videoOptions"
            class="player-height mb-4"
            :loading="fetchingPost"
            :video="activePost && activePost.video"
            :assetUrl="assetUrls"
            :video-thumbnail="activePost.posterImage"
            :options="videoOptions"
            :trackTime="true"
            :videoCompletePercentage="videoCompletePercentage"
            @getVideoTime="getVideoTime"
            @onPause="storeVideoProgress"
            @onEnded="storeVideoProgress"
            @onPlay="updateVideoPlay"
          />
          <audio-player
            v-else-if="activePost.audio"
            id="post-audio"
            :audio="activePost.audio"
            :thumbnail="activePost.posterImage"
            :post="activePost"
            :product="product"
            @getAudioTime="getAudioTime"
            @onPause="storeAudioProgress"
            @onEnded="storeAudioProgress"
            @onPlay="updateAudioPlay"
          />
        </div>
        <div class="w-full">
          <div
            id="post-body"
            class="block bg-white p-5 shadow rounded lesson-body-background col-span-8"
          >
            <span
              v-if="
                currentPostContentType === postContentType.video ||
                currentPostContentType === postContentType.audio
              "
              class="font-semibold post-body-title lessonPrimaryStyle lesson-body-title"
              >ABOUT THIS LESSON</span
            >
            <div
              v-if="currentPostContentType === postContentType.quiz"
              class="p-5 justify-center"
            >
              <div class="flex justify-center">
                <QuizStatusComponent
                  :post="activePost"
                  :categoryId="categoryId"
                  @changeQuizStatus="changeQuizStatus"
                />
              </div>
            </div>
            <div
              v-else-if="currentPostContentType === postContentType.assignment"
              class="p-5 justify-center"
            >
              <div>
                <AssignmentStatus
                  :post="activePost"
                  :categoryId="categoryId"
                  :showRetakeButton="true"
                  :nextPost="nextPost"
                  :completed="completedPostIds.has(nextPost.id)"
                />
              </div>
            </div>
            <div
              v-else
              id="post-description"
              class="lessonSecondaryStyle lesson-body-description custom-word-break"
              v-html="activePost.description"
            ></div>
            <!-- <iframe src frameborder="0"></iframe> -->
            <div
              class="post-status-fetch-message"
              v-if="fetchingPostCompletionStatus"
            >
              Fetching post status..
            </div>
            <button
              id="post-completion-button"
              v-else
              :disabled="disablePostCompletionButton || isAdmin"
              @click="togglePostCompletion()"
              class="mt-5 w-full bg-transparent font-semibold py-2 px-4 border rounded lessonPrimaryStyle"
              :class="[
                markCompletedBtnCss,
                isAdmin ? 'opacity-75 cursor-not-allowed' : '',
              ]"
            >
              <span>{{ postCompletionButtonText }}</span>
            </button>
            <go-to-next-post
              v-if="nextPost && completedPostIds.has(postId)"
              class="mt-4"
              :post="nextPost"
              :next-lesson="nextLesson"
              :completed="completedPostIds.has(nextPost.id)"
            />
          </div>
        </div>
        <div class="w-full" v-if="activePost.commentPermission !== 'hidden'">
          <CommentList
            :productCommentPrivacy="product.commentPrivacy"
            :postId="postId"
            :productId="productId"
            :instructor="instructor"
            :commentPermission="activePost.commentPermission"
            :pendoPayload="{ post: activePost, product, category }"
            :commentIdToScroll="commentIdToScroll"
          />
        </div>
      </div>
      <div class="post-playlist-container col-span-4">
        <post-queue
          v-if="playlist && playlist.length"
          id="post-playlist"
          class="player-height postqueue-background grid grid-rows-post-playlist mb-4 min-h-60"
          :style="`max-height: ${maxHeightOfPlaylist}px; ${
            !activePost.video && `min-height: ${maxHeightOfPlaylist}px`
          }`"
          :product-id="productId"
          :category-id="categoryId"
          :category-title="category.title"
          :playlist="playlist"
          :active-post="postId"
          :completed-posts="completedPostIds"
          :next-category="nextCategoryId"
        />
        <div class="post-materials-container block">
          <PostMaterialsCard
            id="post-materials"
            v-if="activePost.materials && activePost.materials.length"
            class="mb-5"
            :materials="activePost.materials"
          />
          <InstructorCard :instructor="instructor" />
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import CategoryPostBreadcrumbs from '@/components/common/CategoryPostBreadcrumbs.vue'
import VideoPlayer from '@/components/product/VideoPlayer.vue'
import AudioPlayer from '@/components/product/AudioPlayer.vue'
import PostQueue from '@/components/product/PostQueue.vue'
import InstructorCard from '@/components/product/InstructorCard.vue'
import PostMaterialsCard from '@/components/product/PostMaterialsCard.vue'
import GoToNextPost from '@/components/product/GoToNextPost.vue'
import CommentList from '@/components/comments/CommentList.vue'
import QuizStatusComponent from '@/components/assessments/QuizStatusComponent.vue'
import AssignmentStatus from '@/components/assessments/AssignmentStatus.vue'
import { PostContentType } from '@/models/Post'

export default defineComponent({
  components: {
    CategoryPostBreadcrumbs,
    QuizStatusComponent,
    VideoPlayer,
    PostQueue,
    PostMaterialsCard,
    InstructorCard,
    GoToNextPost,
    CommentList,
    AssignmentStatus,
    AudioPlayer,
  },
  props: {
    product: {
      type: Object,
    },
    instructor: {
      type: Object,
    },
    activePost: {
      type: Object,
    },
    category: {
      type: Object,
    },
    videoOptions: {
      type: Object,
    },
    nextPost: {
      type: Object,
    },
    playlist: {
      type: Array,
    },
    disableActions: {
      type: Object,
    },
    videoCompletePercentage: Number,
    nextCategoryId: String,
    markCompletedBtnCss: String,
    postCompletionButtonText: String,
    lessonButton: Object,
    isAdmin: Boolean,
    nextLesson: Object,
    fetchingPost: Boolean,
    fetchingPostCompletionStatus: Boolean,
    disablePostCompletionButton: Boolean,
    completedPostIds: {
      type: Set,
      default: new Set(),
    },
  },
  data() {
    return {
      post: {},
      canRetakeQuiz: false,
      commentIdToScroll: this.$route.query.commentId,
      maxHeightOfPlaylist: 450,
      postContentType: PostContentType,
    }
  },
  computed: {
    currentPostContentType(): string {
      return this.activePost && this.activePost.contentType
    },
    productId(): string {
      return this.$route.params.id
    },
    categoryId(): string {
      return this.$route.params.category_id
    },
    postId(): string {
      return this.$route.params.post_id
    },
    featureFlags() {
      return this.$store.state.featureFlag
    },
    assetUrls(): any {
      return this.activePost && this.activePost.assetUrls
    },
  },
  beforeDestroy() {
    this.storeVideoProgress()
  },
  mounted() {
    this.updatePlaylistHeight()
  },
  methods: {
    updatePlaylistHeight() {
      const videoEle = this.$refs.videoContainer
      if (videoEle)
        this.maxHeightOfPlaylist = videoEle.$el.offsetHeight
          ? videoEle.$el.offsetHeight
          : this.maxHeightOfPlaylist
    },
    togglePostCompletion() {
      this.$emit('togglePostCompletion')
    },
    changeQuizStatus(quizRetake: boolean) {
      this.canRetakeQuiz = quizRetake
    },
    updateVideoPlay() {
      this.$emit('updateVideoPlay')
    },
    storeVideoProgress() {
      this.$emit('storeVideoProgress')
    },
    getVideoTime(currentTime: Array<number>) {
      this.$emit('updateVideoTime', currentTime)
    },
    updateAudioPlay() {
      this.$emit('updateAudioPlay')
    },
    storeAudioProgress() {
      this.$emit('storeAudioProgress')
    },
    getAudioTime(currentTime: Array<number>) {
      this.$emit('updateAudioTime', currentTime)
    },
  },
  watch: {
    activePost() {
      this.updatePlaylistHeight()
    },
  },
})
</script>
<style scoped>
.custom-word-break {
  word-break: break-word;
}
.assignment-button {
  background-color: #3b5998;
  font-weight: bold;
  color: white;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.26);
}
</style>
