import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f38b5718"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  id: "product-breadcrumbs"
}
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.productTitle)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_router_link, {
          to: {
        name: 'product-overview',
        params: { id: _ctx.productId },
        query: { template_id: _ctx.templateId },
      },
          exact: "",
          "active-class": "route-link-active inline-block",
          class: _normalizeClass(_ctx.isPostRoute)
        }, {
          default: _withCtx(() => [
            (_ctx.showIcon)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  class: "px-2",
                  style: _normalizeStyle({ color: _ctx.goBackIconColor })
                }, [
                  _createVNode(_component_BaseIcon, { name: "previousarrow" })
                ], 4))
              : _createCommentVNode("", true),
            _createTextVNode(" " + _toDisplayString(_ctx.productTitle), 1)
          ]),
          _: 1
        }, 8, ["to", "class"]),
        (_ctx.categoryId)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: _normalizeClass(_ctx.isPostRoute)
            }, [
              _createTextVNode(" / "),
              _createVNode(_component_router_link, {
                to: { name: 'category-list', params: { id: _ctx.productId } },
                exact: "",
                "active-class": "route-link-active",
                class: _normalizeClass(_ctx.isPostRoute)
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Categories")
                ]),
                _: 1
              }, 8, ["to", "class"])
            ], 2))
          : _createCommentVNode("", true),
        (_ctx.categoryId)
          ? (_openBlock(), _createElementBlock("span", {
              key: 1,
              class: _normalizeClass(_ctx.isPostRoute)
            }, [
              _createTextVNode(" / "),
              (!_ctx.categoryTitle)
                ? (_openBlock(), _createElementBlock("span", _hoisted_2, "..."))
                : (_openBlock(), _createBlock(_component_router_link, {
                    key: 1,
                    to: {
          name: 'category-overview',
          params: { id: _ctx.productId, category_id: _ctx.categoryId },
        },
                    exact: "",
                    "active-class": "route-link-active",
                    class: _normalizeClass(_ctx.isPostRoute)
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.categoryTitle), 1)
                    ]),
                    _: 1
                  }, 8, ["to", "class"]))
            ], 2))
          : _createCommentVNode("", true),
        (_ctx.postId)
          ? (_openBlock(), _createElementBlock("span", {
              key: 2,
              class: _normalizeClass(_ctx.isPostRoute)
            }, [
              _createTextVNode(" / "),
              (!_ctx.postTitle)
                ? (_openBlock(), _createElementBlock("span", {
                    key: 0,
                    class: _normalizeClass(_ctx.isPostRoute)
                  }, "...", 2))
                : (_openBlock(), _createBlock(_component_router_link, {
                    key: 1,
                    to: {
          name: 'post-overview',
          params: { id: _ctx.productId, category_id: _ctx.categoryId, post_id: _ctx.postId },
        },
                    exact: "",
                    "active-class": "route-link-active",
                    class: _normalizeClass(_ctx.isPostRoute)
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.postTitle), 1)
                    ]),
                    _: 1
                  }, 8, ["to", "class"]))
            ], 2))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}