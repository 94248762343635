<template>
  <div
    v-if="fetchingSubmission"
    class="flex justify-center items-center h-screen"
  >
    <UISpinner size="large" />
  </div>
  <div v-else>
    <div
      v-if="showAssignmentQuestion"
      class="rounded submission-container mx-auto"
      :class="newMobileScreens ? '' : 'border border-gray-300'"
    >
      <div
        v-if="!newMobileScreens || (newMobileScreens && !hideTitle)"
        class="text-center font-semibold"
        :class="newMobileScreens ? 'py-2 text-xl' : 'py-5'"
      >
        {{ assignment.title }}
      </div>
      <div class="mx-4" v-if="!retakingAssignment && !newMobileScreens">
        <div
          v-if="
            lastSubmission &&
            lastSubmission.status !== assessmentStatusOptions.processing
          "
          class="py-5 px-2 rounded-md flex m-auto font-medium"
          :class="`bg-${resultMessageColor}-200`"
        >
          <div class="px-5 flex">
            <span class="pt-px" :class="`text-${resultMessageColor}-500`">
              <BaseIcon
                :name="
                  lastSubmission.status === assessmentStatusOptions.passed
                    ? 'checkcircle'
                    : 'crosswithcircle'
                "
                hwClass="h-8 w-8"
              />
            </span>
            <div class="px-5 self-center" v-html="pageTopMessage"></div>
          </div>
          <router-link
            v-if="
              nextPost &&
              lastSubmission.status === assessmentStatusOptions.passed &&
              !completed
            "
            class="ml-auto pt-2 pr-5 cursor-pointer"
            :class="`text-${resultMessageColor}-500`"
            :to="{
              name: 'post-overview',
              params: {
                id: productId,
                category_id: nextPost.categoryId,
                post_id: nextPost.id,
              },
            }"
          >
            Next
          </router-link>
          <div
            v-if="lastSubmission.status === assessmentStatusOptions.failed"
            class="ml-auto pt-2 pr-5 cursor-pointer"
            :class="`text-${resultMessageColor}-500`"
          >
            <router-link
              tag="div"
              :to="{
                name: `${postContentType}-overview`,
                params: {
                  category_id: categoryId,
                  post_id: postId,
                },
                query: {
                  retake: true,
                },
              }"
              class="flex justify-center pt-5"
            >
              Retake
            </router-link>
          </div>
        </div>
      </div>
      <div
        :class="[
          newMobileScreens ? 'py-1' : 'p-4',
          isShowingResultInMobile ? 'px-4' : '',
        ]"
      >
        <div
          v-if="lastSubmission && assignment.confirmMessage"
          class="mb-2"
          :class="
            newMobileScreens
              ? 'border border-success-new-200 rounded-lg bg-success-100 px-2'
              : ''
          "
        >
          <div
            class="font-bold cursor-pointer"
            id="confirmation-message"
            v-html="
              assignment.confirmMessage?.slice(0, currentConfirmationMsgLength)
            "
          ></div>
          <UIButton
            v-if="assignment.confirmMessage?.length > maxDisplayChars"
            id="toggle-confirmation-message"
            :text="true"
            @click="toggleConfirmationMessage"
          >
            {{
              assignment.confirmMessage?.length === currentConfirmationMsgLength
                ? 'Show less'
                : 'Load More'
            }}
          </UIButton>
        </div>
        <div class="font-bold">Instructions:</div>
        <div
          id="introduction-div"
          class="custom-word-break"
          v-html="assignment.introduction"
        ></div>
        <div v-if="fileList.length" class="py-2">
          <div class="my-1 font-bold">Attached files:</div>
          <FileTab
            v-for="(file, index) in fileList"
            :file="file"
            :key="index"
          />
        </div>
        <div v-if="lastSubmission">
          <span style="font-weight: bold">Submitted Answer:</span>
          <span class="custom-word-break" v-html="lastSubmission.answer"></span>
          <div v-if="submittedFiles.length" class="w-full py-2">
            <div class="my-1 font-bold">Submitted files:</div>
            <FileTab
              v-for="(file, index) in submittedFiles"
              :file="file"
              :key="index"
            />
          </div>
        </div>
        <div v-if="lastSubmission && lastSubmission.feedback">
          <span
            class="font-semibold"
            :class="newMobileScreens ? '' : 'text-xs'"
          >
            Feedback
          </span>
          <div
            class="border custom-word-break"
            :class="
              newMobileScreens
                ? 'bg-primary-50 border-primary-200 px-2 text-gray-900 rounded-lg'
                : 'rounded p-5 text-xs bg-white'
            "
            v-html="lastSubmission.feedback"
          ></div>
        </div>
      </div>
    </div>
    <div v-else class="text-center p-20 flex justify-center">
      <img
        height="300"
        width="300"
        src="https://staging.cdn.msgsndr.com/assets/membership/assessments-home.png"
      />
    </div>
    <router-link
      v-if="!newMobileScreens && showingRetakeButton"
      tag="div"
      :to="{
        name: `${postContentType}-overview`,
        params: {
          category_id: categoryId,
          post_id: postId,
        },
        query: {
          retake: true,
        },
      }"
      class="flex justify-center pt-5"
    >
      <UIButton type="primary" id="assignment-button">
        {{ buttonLabel }}
      </UIButton>
    </router-link>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { AssessmentStatusService, AssignmentService } from '@/services/'
import { UIButton } from '@gohighlevel/ghl-ui'
import UISpinner from '@/components/common/UISpinner.vue'
import FileTab from './FileTab.vue'
import { adminMode } from '@/helper/permission.helper'
import BaseIcon from '../svgicons/BaseIcon.vue'
import { AssessmentStatusOptions } from '@/models/AssessmentStatus'

export default defineComponent({
  props: {
    categoryId: String,
    post: Object,
    showRetakeButton: Boolean,
    nextPost: Object,
    hideTitle: {
      type: Boolean,
      default: () => false,
    },
    completed: Boolean,
  },
  computed: {
    productId(): string {
      return this.$route.params.id
    },
    pageTopMessage(): string {
      const commonMessage = `We have evaulated your assignment and you scored ${this.lastSubmission?.score}%.`
      const resultMessage = `You have ${
        this.lastSubmission.status === this.assessmentStatusOptions.passed
          ? 'successfully passed'
          : 'failed'
      } this assignment.<br>Thank You.`
      return `${commonMessage}<br>${resultMessage}`
    },
    resultMessageColor(): string {
      return this.lastSubmission &&
        this.lastSubmission.status === this.assessmentStatusOptions.passed
        ? 'green'
        : 'red'
    },
    showingRetakeButton(): boolean {
      return (
        (!this.lastSubmission && this.showRetakeButton) ||
        (this.lastSubmission &&
          this.lastSubmission.status === this.assessmentStatusOptions.failed &&
          this.showRetakeButton)
      )
    },
    submissionColor(): string {
      return this.lastSubmission &&
        this.lastSubmission.status === this.assessmentStatusOptions.passed
        ? 'green'
        : 'red'
    },
    postId(): string {
      return this.post ? this.post.id : ''
    },
    isAdmin() {
      return adminMode()
    },
    postContentType(): string {
      return this.post.contentType
    },
    buttonLabel(): string {
      return `${this.lastSubmission ? 'Retake' : 'Start'} ${
        this.contentTypeLabel[this.postContentType]
      }`
    },
    retakingAssignment(): boolean {
      return this.$route.query.retake
    },
    fileList(): Array<{
      size: number
      title: string
      url: string
      type: string
    }> {
      return this.assignment.fileUrls ?? []
    },
    submittedFiles(): Array<any> {
      if (this.lastSubmission) {
        return this.lastSubmission.submission ?? []
      }
      return []
    },
    shouldTruncate() {
      return (
        this.assignment &&
        this.assignment.confirmMessage &&
        this.assignment.confirmMessage.length > this.maxDisplayChars
      )
    },
    newMobileScreens(): boolean {
      return this.$store.getters.newMobileScreens
    },
    isShowingResultInMobile(): boolean {
      return (
        this.newMobileScreens && this.lastSubmission && !this.retakingAssignment
      )
    },
  },
  data() {
    return {
      lastSubmission: null,
      questionCount: 0,
      correctCount: 0,
      incorrectCount: 0,
      fetchingSubmission: false,
      contentTypeLabel: {
        quiz: 'Quiz',
        assignment: 'Assignment',
      },
      assignment: {},
      showAssignmentQuestion: true,
      maxDisplayChars: 300, // Adjust this value as needed
      currentConfirmationMsgLength: 300,
      assessmentStatusOptions: AssessmentStatusOptions,
    }
  },
  async mounted() {
    await this.getLastSubmission()
    await this.getAssignmentByPostId()
  },
  methods: {
    async getLastSubmission() {
      if (this.isAdmin) return // don't make api calls if it's admin
      this.fetchingSubmission = true
      try {
        const fetchedSubmission =
          await AssessmentStatusService.getLastSubmissionByPostId(this.postId)
        this.lastSubmission = fetchedSubmission.data.lastSubmission
        if (this.lastSubmission && !this.retakingAssignment) {
          this.showAssignmentQuestion = true
        }
      } catch (error) {
        console.error(`Error getting last submission`, error)
      }
      this.fetchingSubmission = false
    },
    async getAssignmentByPostId() {
      try {
        this.assignment = await AssignmentService.findById(this.postId)
      } catch (error) {
        console.error(
          `Error getting assignment by post id: ${JSON.stringify(error)}`
        )
      }
    },
    toggleConfirmationMessage() {
      if (this.currentConfirmationMsgLength === this.maxDisplayChars) {
        this.currentConfirmationMsgLength =
          this.assignment.confirmMessage.length
      } else {
        this.currentConfirmationMsgLength = this.maxDisplayChars
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        })
      }
    },
  },
  components: {
    // Button,
    UISpinner,
    FileTab,
    BaseIcon,
    UIButton,
  },
})
</script>
<style scoped>
.height-fifty-percent {
  height: 50%;
}
.height-twenty-five-percent {
  height: 25%;
}

.assignment-button {
  background-color: #3b5998;
  font-weight: bold;
  color: white;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.26);
}

#introduction-div ol,
ul {
  text-align: left;
  padding-left: 0.5em;
}

#introduction-div ol,
ul li {
  list-style-position: outside; /* or 'inside' */
  margin-left: 1em; /* gives 'room' for the numbers to appear in */
}

.custom-word-break {
  word-break: break-word;
}
</style>
