import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-07cd7cdb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UISpinner = _resolveComponent("UISpinner")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.isPortalNavbar ? 'pt-10' : '')
  }, [
    (_ctx.fetching || _ctx.fetchingProduct)
      ? (_openBlock(), _createBlock(_component_UISpinner, {
          key: 0,
          class: "flex justify-center items-center h-screen",
          size: "large"
        }))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(`${_ctx.selectedTheme}Template`), {
            heroSection: _ctx.heroSection,
            product: _ctx.product,
            categoryTree: _ctx.categoryTree,
            postIdsCompleted: _ctx.postIdsCompleted,
            categoryIdsCompleted: _ctx.categoryIdsCompleted,
            completedPosts: _ctx.completedPosts,
            totalPosts: _ctx.totalPosts,
            instructor: _ctx.instructor,
            nextPost: _ctx.nextPost,
            productStarted: _ctx.productStarted,
            logo: _ctx.logo,
            hasProductAccess: _ctx.hasProductAccess,
            onGoToNextPost: _ctx.goToNextPost
          }, null, 40, ["heroSection", "product", "categoryTree", "postIdsCompleted", "categoryIdsCompleted", "completedPosts", "totalPosts", "instructor", "nextPost", "productStarted", "logo", "hasProductAccess", "onGoToNextPost"]))
        ]))
  ], 2))
}